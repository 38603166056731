import { Breadcrumbs } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ServicesDetails from "../../components/services-details";
import ServicesDetailsBox from "../../components/services-details-box";
import Pagination from "../../components/pagination";
import Lottie from "lottie-react";
import Client from "../.././assets/Animation/Small-Large-wide.json";
import ClientMob from "../.././assets/Animation/Small-Large-wide-mob.json";

const BoxData = [
  {
    id: 1,
    Img: "/images/services/client/1.png",
    Heading: "CLIENT EXPERIENCE",
    Disc: `Luxury client experiences comprise of personalized attention, exclusivity, attention-to-detail and a sense of indulgence.<br/>
    The luxury experience starts with the first interaction a client has when discovering a brand and must remain consistently elevated to match their passion and capture loyalty. Attention to detail is critical.<br/>
    JMC emphasises the importance of building relationships through highly personalised and targeted strategies which include Mystery Shopping for research and benchmarking, Service Models and In-store Training to build and convert client relationships in store, and other features such as NPS Services to monitor and leverage feedback.<br/><br/>
    We leverage our deep expertise to seamlessly integrate tools that help you better understand the client, and equip your team to deliver meaningful experiences while simplifying processes and eliminating costs.`,
  },
  {
    id: 2,
    Img: "/images/services/client/2.png",
    Heading: "CLIENT RETENTION",
    Disc: `Client Retention is a key metric to determine client loyalty. Retained clients are also your most valuable, as they have already demonstrated their trust in your brand by making an initial purchase. This trust provides an opportunity to expand and grow their Annual Spend and Life Time Value by implementing selling techniques to cross and upsell clients to new categories and new launches. However, these sales techniques are not possible without a brand demonstrating its commitment to building a relationship and value for the client. Typically, investments made to generate increased retention rates can give far higher returns than activations made to acquire new clients, making this a primary focus for any business.<br/>
     JMC’s Client Retention strategies build, monitor and optimise tools that will drive retention and develop generational clients for your business.`,
  },
  {
    id: 3,
    Img: "/images/services/client/3.png",
    Heading: "CLIENT ACQUISITION",
    Disc: ` Client acquisition is crucial for creating and developing a foothold in the industry, from bringing in new clients to increasing revenue. Acquisition of new clients could range by
    variables such as demography or nationality and the initiatives must be given dedicated support to help spread risk from high dependence and complacency with a recurring clientele. <br/> 
    While client acquisition benefits a brand in ways such as increased sales, generating brand awareness, maintaining and expanding a business; the key goal from client acquisition initiatives for established brands is to generate revenue and profitability.<br/>
JMC applies a custom approach which co-creates exceptional brand experiences to assist with Client Acquisition. We identify prospects who are connoisseurs of the industry through detailed research and deep industry networks which provides brands with personalised introductions and higher return on their investment and initiatives.`,
  },
];

const ClientDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const lottieContainerRef = useRef(null);
  // useEffect(() => {
  //   window.scrollTo(0, 0);

  //   const handleScroll = () => {
  //     const lottieContainer = lottieContainerRef.current;

  //     if (lottieContainer) {
  //       const rect = lottieContainer.getBoundingClientRect();
  //       const isVisible = rect.top >= 0 && rect.top <= window.innerHeight * 0.9;

  //       if (isVisible) {
  //         document.documentElement.style.scrollBehavior = "smooth";
  //         window.scrollTo({
  //           top: 700,
  //         });
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //     // Reset scroll behavior to auto when the component is unmounted
  //     document.documentElement.style.scrollBehavior = "auto";
  //   };
  // }, []);

  return (
    <div>
      {/* breadcrumbs  */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6 text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#707070"
          to="/service"
          className="text-[#707070] fs-14"
        >
          SERVICES
        </Link>
        <Link
          underline="hover"
          color="#11134E"
          href="/AnalyticsService"
          className="text-[#11134E] fs-14"
        >
          CLIENT DEVELOPMENT
        </Link>
      </Breadcrumbs>

      <>
        <ServicesDetails
          MobHeading={"CLIENT DEVELOPMENT"}
          Heading={"CLIENT DEVELOPMENT"}
          SubHeading={"Getting You Closer to the Client"}
          Description={`JMC ensures that the client is at the heart of every business decision. Without a client, the brand is just an idea.<br/><br/>

          Client development is about taking clients on a journey to build trust and create motivation to spend. Our client-centric strategies not only enhance experiences and evoke emotion, but also set foundations, guidelines and opportunities to help you retain and convert more business.<br/><br/>
          
          We believe that Client Experience, Client Retention and Client Acquisition are the three essential aspects of Client Development, each with unique benefits and opportunities, which must be given equal attention to ensure that there is constant revenue for a brand.
          <br/><br/>
          
          Our Client Development service is heavily leveraged by the luxury industry, as we help brands go well beyond the typical CRM functions of organising, monitoring and reporting client interactions. We act as an alignment fulcrum (and provide the secret sauce) for CRM and P&L owners, to co-create and execute strategies that maximise a brand’s full commercial potential and deliver long term, sustainable growth.
          <br/><br/>
          
          Our commitment to excellence and unwavering focus on client success sets us apart as a trusted partner for brands and businesses seeking to transform clients into brand advocates.`}
        />
      </>

      <div>
        {window.innerWidth >= 768 ? (
          <Lottie animationData={Client} />
        ) : (
          <Lottie animationData={ClientMob} />
        )}
      </div>

      <div className="p-5">
        <ServicesDetailsBox BoxData={BoxData} />
      </div>
      <div className="pt-10 pb-20">
        <Pagination
          prevLink={"/BrandManagement"}
          prevPage={"BACK TO BRAND MANAGEMENT"}
          nextPage={"PERFORMANCE MANAGEMENT"}
          nextLink={"/PerformanceManagement"}
          mobHeading={"CLIENT DEVELOPMENT"}
        />
      </div>
    </div>
  );
};

export default ClientDevelopment;
