import React, { useState } from "react";
import Modal from "@mui/material/Modal";

const AboutComp = ({
  image,
  alt,
  title,
  description,
  description2,
  description3,
  description4,
  description5,
  readLink,
  index,
  modalImg,
  suryadescription4,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      {index % 2 === 0 ? (
        <div className="flex bg-[#F4F4F4] rounded-md   @sm:flex-col @sm:px-8 @sm:pt-10">
          <div className="border-b-1 border-[#DBDBDB] pb-2 @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden @sm:px-8">
            <div className="fs-40 text-[#0b0b2f] mx-4 text-center ">
              LEADERSHIP
            </div>
          </div>
          <div className="h-auto w-1/3 @sm:w-full @sm:h-[373px] @sm:mt-5 rounded-md  border-[#F4F4F4] border-2">
            <img
              className="h-[100%] w-full object-cover rounded-md "
              src={image}
              alt={alt}
            />
          </div>
          <div className="p-8 w-2/3 flex flex-col justify-center @sm:w-full @sm:px-0">
            <div className="border-b-2 pb-4 mb-4 fs-32 text-[#0b0b2f] @sm:text-center tracking-wider">
              {title}
            </div>
            <p className="mb-4 ">{description}</p>
            <p className="mb-4 ">{description2}</p>
            <div className="@lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden">
              <p className="mb-4 ">{description3}</p>
              <p className="mb-0  ">{description4}</p>
              <p className="mb-10 ">{description5}</p>
            </div>
            {readLink === "Read More" ? (
              <div
                className="text-[#0b0b2f] @sm:hidden @md:hidden cursor-pointer"
                onClick={handleOpen}
              >
                Read More
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="flex @sm:flex-col  bg-[#F4F4F4] rounded-md mt-16 @sm:mt-0 @sm:px-8 @sm:pb-8">
          <div className="h-[373px] w-fll @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden ">
            <img
              className="h-full w-full object-contain "
              src={image}
              alt={alt}
            />
          </div>
          <div className="p-8 w-2/3 flex flex-col justify-center @sm:w-full @sm:px-0">
            <div className="border-b-2 pb-4 mb-4 fs-32 text-[#0b0b2f] @sm:text-center">
              {title}
            </div>
            <p className="mb-4">{description}</p>
            <p className="mb-4">{description2}</p>
            <div className="@lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden">
              <p className="mb-4 ">{description3}</p>
              <p className="mb-4 ">{description4}</p>
              <p className="mb-0 ">{description5}</p>
            </div>
            {readLink === "Read More" ? (
              <div
                className="text-[#0b0b2f] @sm:hidden @md:hidden cursor-pointer"
                onClick={handleOpen}
              >
                Read More
              </div>
            ) : null}
          </div>
          <div className="h-auto w-1/3 @sm:hidden rounded-md ">
            <img
              className="h-full w-full object-cover rounded-md "
              src={image}
              alt={alt}
            />
          </div>
        </div>
      )}

      {/* modal  */}
      <Modal open={open}>
        <div className="bg-[#FBFBFB] w-full h-full border-none flex flex-row p-6 rounded-2">
          <div className="w-[35%] rounded-2 ">
            <img
              src={modalImg}
              className=" h-[100%] w-[100%] rounded-[7px] "
              style={{ objectFit: "cover" }}
              alt=""
            />
          </div>
          <div className="w-[65%]   flex flex-col justify-center ">
            <div className="top-5 absolute right-0 pr-10">
              <div
                className="flex flex-row justify-end items-end "
                onClick={handleClose}
              >
                <img
                  src="images/about/cross.png"
                  className="h-10 w-10 cursor-pointer"
                  alt=""
                />
              </div>
            </div>
            <div className="flex justify-center flex-col ">
              <div className=" translate -translate-x-14">
                <div className="bg-[#fff] p-6 @2xl:p-14  w-[100%] border-1 border-[#fff] rounded-[8px]">
                  <div className="flex flex-row justify-between border-b-1 border-[#BCBCBC]">
                    <div className="mb-2 text-[#0B0B2F] fs-40 fw-400">
                      {title}
                    </div>
                    <div className="mb-2">
                      <img
                        src="images/about/linkedin.png"
                        className="h-8 w-8 cursor-pointer"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="mb-4 fs-18 fw-400 ">{description}</p>
                    <p className="mb-4  fs-18 fw-400 ">{description2}</p>
                    <p className="mb-4  fs-18 fw-400">{description3}</p>
                    <p className="mb-0 fs-18 font-bold">{description4}</p>
                    <p className="mb-4 fs-18 fw-400 ">{suryadescription4}</p>
                    <p className="  fs-18 fw-400">{description5}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AboutComp;
