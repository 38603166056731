
import { applyMiddleware,createStore } from "redux";
import storage from 'redux-persist/lib/storage';
import { persistReducer,persistStore } from 'redux-persist';
import reducers from "./Reducers/Combinereducers";
import { thunk } from "redux-thunk";


const persistConfig = {
    key: 'persist-key', // key is required
    storage, // storage is required
  };

  const persistedReducer = persistReducer(persistConfig,reducers)
  const store = createStore(persistedReducer, applyMiddleware(thunk));

  
const persistor = persistStore(store);

export default store ;
export {persistor};