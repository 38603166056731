import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Newsletter } from "../Redux/Actions/FormActions";

const navlinks = [
  {
    title: "About",
    link: "/about-us",
  },
  {
    title: "Services",
    link: "/service",
  },
  {
    title: "Heritage",
    link: "/heritage",
  },
  {
    title: "Clients",
    link: "/Client",
  },
  {
    title: "Careers",
    link: "/career",
  },
  {
    title: "Contact",
    link: "/contact",
  },
];

const policyLinks = [
  {
    title: "Terms of use",
    link: "/",
  },
  {
    title: "Privacy Policy",
    link: "/",
  },
  {
    title: "Cookie Policy",
    link: "/",
  },
];

const Footer = () => {
  const location = useLocation();
  const [news, setnews] = useState({
    email: "",
    name: "",
  });
  // console.log("location", location.pathname);
  const dispatch = useDispatch();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleInputChange = (key, value) => {
    console.log("key--", key, " Value--", value);
    setnews({ ...news, [key]: value });
  };

  const handlenewssubmit = (e) => {
    e.preventDefault();
    console.log(news);
    dispatch(Newsletter(news));
  };

  return (
    <>
      {/* desktop footer  */}
      <footer className="@sm:hidden @md:hidden">
        {location.pathname === "/home" ? (
          <div
            className=" py-4 text-white text-center fs-14"
            style={{ background: "radial-gradient(circle, #11115a, #0d0d3a)" }}
          >
            &#169; 2023 JMC. All rights reserved.
          </div>
        ) : (
          <>
            <div
              className=" py-14 text-white"
              style={{
                background: "radial-gradient(circle, #11115a, #0d0d3a)",
              }}
            >
              <div className="flex justify-between mx-auto w-11/12 border-b-1 border-white py-4">
                <div>
                  {navlinks.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={item.link}
                        onClick={scrollToTop}
                        className="mr-11 fs-16 text-white"
                      >
                        {item.title}
                      </Link>
                    );
                  })}
                </div>
                <div className="flex items-center text-white fs-16">
                  Subscribe to our newsletter-&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    <form>
                      <input
                        type="email"
                        id="newsletter-email"
                        className="bg-transparent text-[#fff] border-1 fs-16 pb-1 pt-1.5 px-2 mr-2 min-w-[250px]"
                        placeholder="Enter your e-mail"
                        value={news.email}
                        onChange={(e) =>
                          handleInputChange("email", e.target.value)
                        }
                      />
                      <button
                        className="px-8 border-1 fs-16 pb-1 pt-1.5 text-white cursor-pointer"
                        onClick={(e) => handlenewssubmit(e)}
                      >
                        Submit
                      </button>
                    </form>
                  </span>
                </div>
              </div>
              <div className="flex justify-between mx-auto w-11/12 py-4">
                <div>
                  {policyLinks.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={item.link}
                        className="mr-11 fs-16 text-white"
                      >
                        {item.title}
                      </Link>
                    );
                  })}
                </div>
                <div className="flex">
                  <img
                    src="images/footer/mail-icon.svg"
                    alt="mail icon"
                    className="mr-4 cursor-pointer"
                  />
                  <img
                    src="images/footer/linkedin-icon.svg"
                    alt="linkedin icon"
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="bg-white py-4 text-[#0b0b2f] text-center fs-14">
              &#169; 2023 JMC. All rights reserved.{" "}
            </div>
          </>
        )}
      </footer>

      {/* mobile footer  */}
      <footer className=" @lg:hidden @xl:hidden @2xl:hidden">
        {location.pathname === "/" ? (
          <div
            className="py-4 fs-16 text-white text-center"
            style={{ background: "radial-gradient(circle, #11115a, #0d0d3a)" }}
          >
            &#169; 2023 JMC. All rights reserved.
          </div>
        ) : (
          <>
            <div
              className="py-10 px-14 text-white"
              style={{
                background: "radial-gradient(circle, #11115a, #0d0d3a)",
              }}
            >
              <div className="fs-16 text-white">
                Subscribe to our newsletter-&nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  <form>
                    <input
                      type="email"
                      id="newsletter-email"
                      className="bg-transparent text-[white] border-1 px-2 py-1 mr-2 w-full my-3"
                      placeholder="Enter your e-mail"
                      value={news.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                    />
                    <div className="flex flex-row justify-end">
                      {/* <input
                        type="submit"
                        className="px-8 fs-16 py-1 border-1 text-white cursor-pointer  my-1 "
                        
                      /> */}
                      <button
                        className="px-8 border-1 fs-16 pb-1 pt-1.5 text-white cursor-pointer"
                        onClick={(e) => handlenewssubmit(e)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </span>
              </div>

              <div className="flex justify-between  w-full border-b-1 border-white py-3">
                <div className="flex">
                  <img
                    src="images/footer/mail-icon.svg"
                    alt="mail icon"
                    className="mr-4 cursor-pointer"
                  />
                  <img
                    src="images/footer/linkedin-icon.svg"
                    alt="linkedin icon"
                    className="cursor-pointer"
                  />
                </div>
              </div>

              <div className="mt-3 flex flex-row">
                <div>
                  {navlinks.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={item.link}
                        className="flex flex-col fs-16 mb-3 text-white"
                      >
                        {item.title}
                      </Link>
                    );
                  })}
                </div>
                <div className="ml-20">
                  {policyLinks.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={item.link}
                        className="flex flex-col fs-16 mb-3 text-white"
                      >
                        {item.title}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="bg-white py-4 text-[#0b0b2f] fs-14 text-center">
              &#169; 2023 JMC. All rights reserved.
            </div>
          </>
        )}
      </footer>
    </>
  );
};

export default Footer;
