import React from "react";

const ServicesDetails = (props) => {
  return (
    <div>
      <div className="pt-14 px-48 @sm:px-14 @sm:pt-8 @md:px-24 @2xl:px-60">
        <section className=" mb-10 @sm:mb-8 ">
          {/* mobile heading  */}
          <div className="border-b-1 border-[#DBDBDB] @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden @sm:mb-2 pb-1 ">
            <div className="fs-40 fw-400 text-center pb-1 tracking-widest ">
              {props.MobHeading}
            </div>
          </div>
          {/* desktop heading  */}
          <div className="flex items-center w-full  justify-between mb-7 @md:mb-2 @sm:hidden">
            <div className=" ">
              <img
                className="@lg:w-[1.4vw]"
                src="images/about/spear.svg"
                alt="left border"
              />
            </div>
            <div
              className={`${props.HeadingWidth} border-1 border-[#DBDBDB]  ml-1 @md:w-[20%] w-auto @lg:w-[25%] @2xl:w-[25%] @xl:w-[25%]`}
            ></div>
            <div
              //  className="fs-40 text-[#0b0b2f] mx-4 tracking-widest w-auto  text-center"
              className={`${props.HeadingTxtWidth} fs-40 text-[#0b0b2f] mx-4 tracking-widest w-auto  text-center`}
            >
              {props.Heading}
            </div>
            <div
              className={`${props.HeadingWidth} border-1 border-[#DBDBDB] mr-1 w-auto @md:w-[20%] @lg:w-[25%] @2xl:w-[25%] @xl:w-[25%]`}
            ></div>
            <div className="">
              <img
                className="@lg:w-[1.4vw]  rotate-180"
                src="images/about/spear.svg"
                alt="left border"
              />
            </div>
          </div>
          <div className="text-center text-[#0B0B2F] fs-32 ">
            {props.SubHeading}
          </div>

          {/* description  */}
          <div
            className={`${props.DescriptionTopPadding} px-20 @sm:px-0 @md:px-40 flex flex-col justify-center pt-12 @sm:pt-6 @md:pt-6`}
          >
            <div
              className="fs-16 fw-400 text-[#545454] text-center @sm:text-left "
              dangerouslySetInnerHTML={{ __html: props.Description }}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default ServicesDetails;
