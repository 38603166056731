import React, { useState } from "react";

const ServicesDetailsBox = (props, items) => {
  const [showFullDesc, setShowFullDesc] = useState(
    new Array(props.BoxData.length).fill(false)
  );
  return (
    // <div className="mt-10">
    //   {props.BoxData.map((items, index) => {
    //     const isAlternate = index % 2 !== 0;
    //     return (
    //       <div
    //         className={`items-center justify-center flex  ${
    //           isAlternate ? "flex-row-reverse  " : "flex-row   "
    //         }`}
    //       >
    //         <div className="w-[70%] @sm:w-[85%] bg-[#F4F4F4] my-10 @sm:my-5 rounded-lg ">
    //           <div className="flex @sm:flex-col w-full">
    //             <div
    //               className={`w-[35%] @sm:w-full @sm:p-5 h-full ${
    //                 isAlternate ? "order-2" : ""
    //               }`}
    //             >
    //               <img
    //                 width={"100%"}
    //                 height={"100%"}
    //                 style={{ objectFit: "cover" }}
    //                 src={items.Img}
    //               />
    //             </div>
    //             <div
    //               className={`px-10 pt-7 @md:p-5 @sm:px-10 @sm:py-5 w-[65%] @sm:w-full h-full ${
    //                 isAlternate ? "order-1 @sm:order-2" : ""
    //               }`}
    //             >
    //               <p className="fs-32 text-[#0B0B2F] @sm:text-center">
    //                 {items.Heading}
    //               </p>
    //               <div className="w-full border-b-2 border-[#BCBCBC] py-1 pb-5" />
    //               <p className="pt-5 fs-18">{items.Disc}</p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   })}
    // </div>

    <div className="mt-10">
      {props.BoxData.map((items, index) => {
        const isAlternate = index % 2 !== 0;
        const characterLimit = 525;

        const truncatedDesc =
          items.Disc.length > characterLimit
            ? items.Disc.substring(0, characterLimit) + "..."
            : items.Disc;

        return (
          <div
            key={index}
            className={`items-center justify-center flex ${
              isAlternate ? "flex-row-reverse" : "flex-row"
            }`}
          >
            <div className="w-[70%] @sm:w-[85%] bg-[#F4F4F4] my-10 @sm:my-5 rounded-lg ">
              <div className="flex @sm:flex-col w-full">
                <div
                  className={`w-[35%] @sm:w-full  h-full ${
                    isAlternate ? "order-2" : ""
                  }`}
                >
                  <img
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "cover" }}
                    src={items.Img}
                    alt={`Image ${index}`}
                  />
                </div>
                <div
                  className={`px-10 pt-7 @md:p-5 @sm:px-6 @sm:py-6 w-[65%] @sm:w-full h-full ${
                    isAlternate ? "order-1 @sm:order-2" : ""
                  }`}
                >
                  <p className="fs-32 text-[#0B0B2F] @sm:text-center tracking-widest">
                    {items.Heading}
                  </p>
                  <div className="w-full border-b-2 border-[#BCBCBC] py-1 pb-5 @sm:pb-2" />
                  <p
                    className="pt-5 fs-18"
                    dangerouslySetInnerHTML={{
                      __html: showFullDesc[index] ? items.Disc : truncatedDesc,
                    }}
                  >
                    {/* {showFullDesc[index] ? items.Disc : truncatedDesc} */}

                    {/* {items.Disc.length > characterLimit && (
                      <button
                        className="text-[#0B0B2F] fs-18 font-bold cursor-pointer ml-5 @sm:ml-1"
                        onClick={() => {
                          const newShowFullDesc = [...showFullDesc];
                          newShowFullDesc[index] = !newShowFullDesc[index];
                          setShowFullDesc(newShowFullDesc);
                        }}
                      >
                        {showFullDesc[index] ? "Read Less" : "Read More"}
                      </button>
                    )} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ServicesDetailsBox;
