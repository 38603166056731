import { BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";
import PagesRoutes from "./routes";
import Header from "./components/header";
import Footer from "./components/footer";

function App() {
  return (
    <>
      {/* <JmcScreen /> */}
      <BrowserRouter>
        <Container>
          <PagesRoutes />
        </Container>
      </BrowserRouter>
    </>
  );
}

const Container = ({ children }) => {
  const location = useLocation();
  console.log("location", location);
  return (
    <>
      {location.pathname !== "/" && location.pathname !== "/home" && (
        <div className="sticky top-0 z-20">
          <Header />
        </div>
      )}
      {children}
      {location.pathname !== "/" && location.pathname !== "/home" && (
        <div className="">
          <Footer />
        </div>
      )}
    </>
  );
};

export default App;
