import axios from "axios";
import { baseURL } from "../Constants/Urls";


export const API = axios.create({
    baseURL: baseURL,
});


const interceptor = (apiInstance) => {
    apiInstance.interceptors.request.use(
        async (config) => {
           
          
            config.headers.Accept = 'application/json';
    
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

interceptor(API); 