import React, { useEffect, useState } from "react";
import HomeScreen from "./home-screen";

const JmcScreen = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className={`fixed top-0 left-0 h-full bg-gradient-to-bl to-[#0d0d3a] from-[#11115a]  text-white transition-transform transform z-20 ${
          isOpen
            ? "translate-x-0 duration-[3500ms]"
            : "-translate-x-full duration-[3500ms]"
        }`}
      >
        <button
          onClick={toggleSidebar}
          className="z-30 fixed w-full h-full  focus:outline-none"
        >
          <div className="w-full flex justify-end">
            <img
              className="w-[12.6vw] h-[10vw] object-cover object-left"
              alt="logo"
              src="/images/header/logo.svg"
            />
          </div>
        </button>
        <div className="p-4 w-[50vw] h-full"></div>
      </div>

      <div
        className={`fixed top-0 right-0 h-full bg-gradient-to-br from-[#11115a] to-[#0d0d3a] text-white transition-transform transform  z-20 ${
          isOpen
            ? "translate-x-0  duration-[3500ms]"
            : "translate-x-full duration-[3500ms]"
        }`}
      >
        <button
          onClick={toggleSidebar}
          className="z-30 fixed w-full h-full  focus:outline-none"
        >
          <div className="w-full flex justify-start">
            <img
              className="w-[12.6vw] h-[10vw] object-cover object-right"
              alt="logo"
              src="/images/header/logo.svg"
            />
          </div>
        </button>
        <div className="p-4 w-[50vw] h-full"></div>
      </div>

      {window.innerWidth >= 768 ? (
        <>
          <div>
            <HomeScreen />
          </div>
        </>
      ) : (
        <>
          <div>
            <HomeScreen />
          </div>
        </>
      )}
    </>
  );
};

export default JmcScreen;
