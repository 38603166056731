import { Breadcrumbs } from "@mui/material";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Careerdata } from "../Redux/Actions/FormActions";
import Select from "react-select";

const CareerScreen = () => {
  const [SelectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [department, setdepartment] = useState();
  const [linkedin, setlinkedin] = useState();
  const [about, setabout] = useState();
  const [message, setmessage] = useState();

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the file input
    fileInputRef.current.click();
  };
 

  const handleFileChange = (event) => {
    // Handle the selected file
    const selectedFile = event.target.files[0];
    console.log("Selected file:", selectedFile);

    setSelectedFile(selectedFile);
    // You can perform further actions with the file, such as uploading it to a server.
  };

  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length <= maxLength) {
      return fileName;
    } else {
      return fileName.slice(0, maxLength) + "...";
    }
  };

  const InterestOptions = [
    { value: "", label: "Select the Department of Interest*" },
    { value: "option1", label: "ANALYTICS & INSIGHTS" },
    { value: "option2", label: "BRAND MANAGEMENT" },
    { value: "option3", label: "CLIENT DEVELOPMENT" },
    { value: "option4", label: "PERFORMANCE MANAGEMENT" },
    { value: "option5", label: "NEW MARKET EXPANSION" },
  ];
  const HearAbout = [
    { value: "", label: "Where did you hear about us?" },
    { value: "option1", label: "ANALYTICS & INSIGHTS" },
    { value: "option2", label: "BRAND MANAGEMENT" },
    { value: "option3", label: "CLIENT DEVELOPMENT" },
    { value: "option4", label: "PERFORMANCE MANAGEMENT" },
    { value: "option5", label: "NEW MARKET EXPANSION" },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: 0,
      border: 0, // Set border to 0
      borderColor: state.isFocused ? "#00f" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #fff" : provided.boxShadow,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#A7A7A7",
      padding: 0, // Change the color of the selected value
    }),
  };

  const handleSubmit = (
    name,
    email,
    department,
    file,
    linkedin,
    about,
    message
  ) => {
    dispatch(
      Careerdata(name, email, department, file, linkedin, about, message)
    ).then((res) => {
      setname("");
      setemail("");
      setdepartment("");
      setlinkedin("");
      setabout("");
      setmessage("");
    });
  };

  return (
    <div className="">
      {/* breadcrumbs  */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6  text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#11134E"
          href="/contact"
          className="text-[#11134E] fs-14"
        >
          CAREERS
        </Link>
      </Breadcrumbs>

      {/* <div className="pt-14 px-48 @sm:px-0 @sm:pt-2 @md:px-24 @2xl:px-60"> */}
      <div className="pt-14 px-48  @sm:px-5 @sm:pt-8 @md:px-24 @2xl:px-60">
        {/* section 1 */}
        <section className=" mb-12 @sm:mb-5 ">
          {/* desktop heading  */}
          <div className="flex items-center justify-center mb-10 @sm:hidden">
            <div className="">
              <img
                className="@lg:w-[1.4vw]"
                src="images/about/spear.svg"
                alt="left border"
              />
            </div>
            <div className="border-1 border-[#DBDBDB] w-[36vw] ml-1"></div>
            <div className="fs-40 text-[#0b0b2f] mx-4 tracking-widest">
              CAREERS
            </div>
            <div className="border-1 border-[#DBDBDB] w-[36vw] mr-1"></div>
            <div className="">
              <img
                className="@lg:w-[1.4vw]  rotate-180"
                src="images/about/spear.svg"
                alt="left border"
              />
            </div>
          </div>
          {/* mobile heading  */}
          <div className="border-b-1 border-[#DBDBDB] @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden @sm:mb-4 ">
            <div className="pb-1 tracking-widest text-center fs-40 fw-400">
              CAREERS
            </div>
          </div>
        </section>
        {/* section 2  */}
        <section className="mb-8">
          <div className="border-1 border-[#F5F5F5] bg-[#F5F5F5] px-32 py-6 @sm:px-8">
            {/* description  */}
            <div className="border-b-1 border-[#BCBCBC]">
              <p className="fs-32 fw-400 text-[#0B0B2F] text-center mb-4 @sm:text-left">
                Curious about working with us?
              </p>
            </div>
            {/* inputs  */}
            <div className="mt-5">
              <div className="border-1 border-[#fff] bg-[#fff] py-3 px-[24px] rounded-md  @sm:pt-1 @sm:pb-1 ">
                <input
                  type="text"
                  placeholder="Name*"
                  className="fs-16 fw-400 text-[#A7A7A7] w-full"
                  style={{ outline: "none" }}
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="border-1 border-[#fff] bg-[#fff] py-3 px-[24px] mt-4 rounded-md @sm:pt-1 @sm:pb-1">
                <input
                  type="text"
                  placeholder="Email*"
                  className="fs-16 fw-400 text-[#A7A7A7] w-full"
                  style={{ outline: "none" }}
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </div>
            {/* select input and message textarea  */}
            <div className="mt-4">
              <div className="border-1 border-[#fff]  bg-[#fff] py-3 px-4 @sm:px-2 rounded-md  @sm:pt-1 @sm:pb-1">
                <Select
                  className="p-0 fs-16"
                  id="selectOption"
                  options={InterestOptions}
                  defaultValue={InterestOptions[0]}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    border: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgray",
                      primary: "#11115A",
                    },
                  })}
                  styles={customStyles}
                  onChange={(e) => {
                    setdepartment(e.value);
                    console.log("department------------", e);
                  }}
                />
              </div>

              <div className="border-1 border-[#fff] bg-[#fff] py-2 px-[24px] mt-4 rounded-md @sm:pt-2 @sm:pb-2">
                <div className="flex items-center justify-between w-full">
                  <p className="fs-16 fw-400 text-[#A7A7A7] w-full @sm:w-[50%] ">
                    Upload CV*
                  </p>
                  <p className="border border-[#707070] text-center p-1 rounded-md w-full fs-16 fw-400 text-[#A7A7A7] ">
                    {/* {!selectedFile
                      ? "Upload your CV file"
                      : `Selected File: ${selectedFile.name}`} */}

                    {!SelectedFile
                      ? "Upload your CV file"
                      : `Selected File: ${truncateFileName(
                          SelectedFile.name,
                          20
                        )}`}
                    <div className="">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </div>
                  </p>

                  <button
                    onClick={handleButtonClick}
                    style={{
                      background: "radial-gradient(circle, #11115a, #0d0d3a)",
                    }}
                    className="text-white fs-16 fw-400  p-1 w-[45%] rounded-md ml-5 @sm:hidden"
                  >
                    Upload
                  </button>
                </div>
                <button
                  onClick={handleButtonClick}
                  style={{
                    background: "radial-gradient(circle, #11115a, #0d0d3a)",
                  }}
                  className="text-white fs-16 fw-400  p-1 w-full rounded-md mt-2 @md:hidden @lg:hidden @lgtab:hidden @xl:hidden @2xl:hidden "
                >
                  Upload
                </button>
              </div>

              <div className="border-1 border-[#fff] bg-[#fff] py-3 px-[24px] mt-4 rounded-md @sm:pt-1 @sm:pb-1">
                <input
                  type="text"
                  placeholder="LinkedIn Profile URL"
                  className="fs-16 fw-400 text-[#A7A7A7] w-full"
                  style={{ outline: "none" }}
                  value={linkedin}
                  onChange={(e) => setlinkedin(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <div className="border-1 border-[#fff]  bg-[#fff] py-3 px-4 @sm:px-2 rounded-md  @sm:pt-1 @sm:pb-1">
                  <Select
                    className="p-0 fs-16"
                    id="selectOption"
                    options={HearAbout}
                    defaultValue={HearAbout[0]}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "lightgray",
                        primary: "#11115A",
                      },
                    })}
                    styles={customStyles}
                    onChange={(e) => {
                      setabout(e.value);
                      console.log("about------------", e.value);
                    }}
                  />
                </div>
              </div>

              <div className=" mt-4  bg-[#fff] py-3 px-[24px]  @sm:pt-1 @sm:pb-1 rounded-md">
                <textarea
                  placeholder="Tell us something about yourself"
                  rows="12"
                  cols="50"
                  className="fs-16 fw-400 text-[#A7A7A7] w-full"
                  style={{ outline: "none" }}
                  value={message}
                  onChange={(e) => setmessage(e.target.value)}
                ></textarea>
              </div>
            </div>
            {/* submit button  */}
            <div className="@sm:hidden">
              {/* <div className="cursor-pointer border-1 border-[#0B0B2F] bg-[#0B0B2F] w-full h-10 rounded-[7px] mt-4 text-[#F2F2F2] fs-24 fw-400 flex flex-row justify-center items-center">
                Submit
              </div> */}
              <button
                style={{
                  background: "radial-gradient(circle, #11115a, #0d0d3a)",
                }}
                className="text-white fs-24 fw-400  p-1 w-[100%] rounded-md py-2 @sm:hidden mt-4"
                onClick={() => {
                  handleSubmit(
                    name,
                    email,
                    department,
                    SelectedFile,
                    linkedin,
                    about,
                    message
                  );
                  console.log("final submit-----------------", handleSubmit);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </section>

        {/* mobile submit button  */}
        <div className="mt-5 @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden mb-12 ">
          {/* <button
            style={{
              background: "radial-gradient(circle, #11115a, #0d0d3a)",
            }}
            className="border-1 @sm:py-5 border-[#0B0B2F] bg-[#0B0B2F] w-full h-6 rounded-[3px] text-[#F2F2F2] fs-14 fw-400 flex flex-row justify-center items-center"
          >
            Submit
          </button> */}
          <button
            style={{
              background: "radial-gradient(circle, #11115a, #0d0d3a)",
            }}
            className="text-white fs-16 fw-400  p-1 w-[100%] rounded-md py-2  mt-4"
            onClick={() =>
              handleSubmit(
                name,
                email,
                department,
                SelectedFile,
                linkedin,
                about,
                message
              )
            }
          >
            Submit
          </button>
        </div>
      </div>
    </div>

    
  );
};

export default CareerScreen;
