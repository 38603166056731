import { ActionConstants } from "./ActionConstants";

import { API } from "../../Apis/Apis-services";
import {
  CAREER_DATA,
  CONTACT_US,
  SUBSCRIBE,
} from "../../Constants/Apisconstants";
import { toast } from "react-toastify";

export const Contact = (request) => () => {
  return API.post(`${CONTACT_US}`, { ...request })
    .then((data) => toast.success(data.data.message))
    .catch((err) => {
      const error = err.response.data.errors;

      Object.keys(error).forEach((field) => {
        error[field].forEach((errorMessage) => {
          toast.error(errorMessage);
        });
      });
    });
};

export const Careerdata =
  (name, email, depart, file, linkedin, about, message) => () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("department", depart);
    formData.append("resume", file);
    formData.append("linkedin_url", linkedin);
    formData.append("about_us", about);
    formData.append("message", message);

    return API.post(`${CAREER_DATA}`, formData)
      .then((data) => {
        toast.success(data.data.message);
      })
      .catch((err) => {
        const error = err.response.data.errors;

        Object.keys(error).forEach((field) => {
          error[field].forEach((errorMessage) => {
            toast.error(errorMessage);
          });
        });
      });
  };

export const Newsletter = (request) => () => {
  return API.post(`${SUBSCRIBE}`, { ...request })
    .then((data) => {
      toast.success(data.data.message);
    })
    .catch((err) => console.log(err));
};
