import React from "react";
import { Link } from "react-router-dom";

const Pagination = (props) => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex justify-center items-center  ">
      <div className="w-[70%] ">
        <div className="flex justify-between items-center">
          <Link to={props.prevLink}>
            <div onClick={scrollToTop} className="flex justify-center items-center">
              <img
                style={{ objectFit: "contain" }}
                className="w-[18px] h-[18px] "
                src="/images/services/back.png"
              />
              <div className="ml-6 text-[#11134E] fs-16 @sm:hidden">
                {props.prevPage}
              </div>
            </div>
          </Link>
          <div onClick={scrollToTop} className="@md:hidden text-center @lg:hidden @lgtab:hidden @xl:hidden @2xl:hidden text-[#11134E] fs-16">
            {props.mobHeading}
          </div>
          <Link to={props.nextLink}>
            <div onClick={scrollToTop} className="flex justify-center items-center">
              <div className="mr-6 text-[#11134E] fs-16 @sm:hidden">
                {props.nextPage}
              </div>
              <img
                style={{ objectFit: "contain" }}
                className="w-[18px] h-[18px]"
                src="/images/services/next.png"
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
