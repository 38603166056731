import { Breadcrumbs, Container } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import AboutComp from "../components/common/about-comp";
import AdvisoryBoardComp from "../components/about/AdvisoryBoardComp";
import FadeIn from "../components/fade-in";

const data = [
  {
    image: "images/about/tikka-singh.png",
    title: "Tikka Singh",
    alt: "Tikka Singh",
    readLink: "Read More",
    description:
      "Before starting JMC he worked in banking for over a decade with positions in New York, Hong Kong, London and Zurich. In 1982, he founded JMC with the intention to help luxury brands seamlessly navigate through ever-evolving market conditions and client behaviours.",
    description2:
      'Dubbed the "guru of luxury", his associations with brands are exceptional and span years and in some cases decades. Through his fourty year-long long career he has worked with the most formidable conglomerates of luxury and a cross-section of the most celebrated independent family-owned brands, this includes: Italy, France, Spain, the Middle East and India. The advisory and consultancy is sector-agnostic and has shaped strategy in the multiple segments of the luxury industries including: Accessories, Perfumes, Cosmetics, Spirits, Fashion and Fine Jewellery.',
    description3:
      "Notably, his first projects were with [XYZ and XYZ] and he helped these brands access India even when it was a “closed market” (.. not open to foreign entities.) Tikka joined the LVMH group in 1994 as its Chief Representative in Asia. In 1998 he was appointed to a full-time board advisory position, this deep association lasted for over 16 years, where helped with entry strategies, locations, communications and marketing - apart from being credited with the introduction of Moet Hennessy, perfume as well as cosmetic ranges into the conglomerate.",
    description4:
      "[Would you like to individually mention the other brands he worked with under the LVMH aegis - ? Or pivotal projects under JMC?] Let’s discuss with him",
    description5:
      "Continuing his centuries-old family legacy, he is a strong advocate of the protection, preservation, restoration and the promotion of heritage- especially to be able to pass onto the next generation. He is actively involved in various philanthropic projects in his homeland Punjab. (not sure about this)",
    modalImg: "images/about/tikka-singh-modal.png",
  },
  {
    image: "images/about/surya-singh.png",
    title: "Surya Singh",
    alt: "Surya Singh",
    readLink: "Read More",
    description:
      "Surya is an experienced luxury executive. His curiosity, result-oriented work ethic and passion for the industry gave him high level responsibility and growth very early in his career.",
    description2:
      "He has accumulated invaluable experience at some of the world's leading brands such as Louis Vuitton, Ralph Lauren and VistaJet, where he embraced integrating the functions of elevating client experiences, optimising talent and driving profitability. Whether working his way up from a Fifth Avenue sales floor, to managing retail or becoming a Vice President of Sales and Business Development, the goal has always to make immediate but lasting impact with those he works with.",
    description3:
      "Sharp acumen guided his pivot from fashion to private aviation. He realized even though luxury businesses operated very differently, the core focus on the client could never be compromised and that to drive meaningful performance, data is essential.  His fondness for the peculiarities of globalized omnichannel retail emerged from being highly skilled at balancing core brand ethics and priorities, with localised market strategies. ",
    description4:
      "Surya shadowed his father, Chairman of JMC from a young age and gained intimate exposure to the consulting practice. His remarkable family heritage augments his knowledge of the luxury ecosystem and also strengthens his affiliation with pioneers in the industry. ",
    description5:
      "Today, he identifies transformational frameworks that  generate sustainable commercial growth and collaborative cultures.",
    suryadescription4:
      "Surya shadowed his father, Chairman of JMC from a young age and gained intimate exposure to the consulting practice. His remarkable family heritage augments his knowledge of the luxury ecosystem and also strengthens his affiliation with pioneers in the industry. ",
    modalImg: "images/about/surya-singh.png",
  },
];

const advisoryData = [
  {
    image: "images/about/surya-singh-2.png",
    alt: "Advisors Name",
    name: "Advisory  Name",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    image: "images/about/surya-singh-2.png",
    alt: "Advisors Name",
    name: "Advisory  Name",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    image: "images/about/surya-singh-2.png",
    alt: "Advisors Name",
    name: "Advisory  Name",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

const About = () => {
  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6 text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#11134E"
          href="/service"
          className="text-[#11134E] fs-14"
        >
          ABOUT{" "}
        </Link>
      </Breadcrumbs>
      <section className="pt-14 mb-20 @sm:mb-14 @sm:pt-8 @sm:px-8">
        <div className="flex items-center justify-center">
          <div className="w-[80%]">
            <div className="flex items-center justify-center mb-12 @sm:hidden ">
              <div className="">
                <img
                  className="@lg:w-[1.4vw"
                  src="images/about/spear.svg"
                  alt="left border"
                />
              </div>
              <div className="border-1 border-[#DBDBDB] w-[36vw] ml-1"></div>
              <div className="fs-40 text-[#0b0b2f] mx-4 tracking-widest">
                ABOUT
              </div>
              <div className="border-1 border-[#DBDBDB] w-[36vw] mr-1"></div>
              <div className="">
                <img
                  className="@lg:w-[1.4vw]  rotate-180"
                  src="images/about/spear.svg"
                  alt="left border"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b-1 border-[#DBDBDB] @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden">
          <div className="pb-1 tracking-widest text-center fs-40 fw-400">
            ABOUT
          </div>
        </div>
        <div className="flex items-center justify-center ">
          <div className="@lg:w-[70%] @xl:w-[70%] @2xl:[70%] w-[65%] @sm:w-full @sm:pt-4">
            {/* @lg:px-64  @sm:pt-4 @md:px-20 */}
            <p className="text-center fs-18 @sm:text-left">
              Established in 1982, JMC is a boutique, global management
              consultancy devoted to the Luxury industry. We have been a trusted
              partner for many of the world’s leading luxury houses for over 40
              years.
            </p>
            <p className="my-4 text-center @sm:text-left fs-18">
              Our purpose remains dedicated to creating value for our clients by
              enhancing brand loyalty and delivering sustainable commercial
              growth. The vision has always been to build enduring brand
              legacies for generations to cherish.
            </p>
            <p className="my-4 text-center @sm:text-left fs-18">
              Our data-driven and client-centric approach is the foundation for
              a suite of services and commercial strategies, which we
              personalise to support and manage brands, clients, performance and
              new market expansions.
            </p>
            <p className="my-4 text-center @sm:text-left fs-18">
              Founded by Mr. Tikka Singh, we are still family-owned, with global
              reach and impact. The family’s unique heritage emphasizes the
              innate passion, expertise for exceptional savoir-faire and
              timeless branding.
            </p>
            <p className="my-4 @sm:mb-0 text-center @sm:text-left fs-18">
              JMC has always been committed to enhancing the future global
              ecosystem of Luxury by preserving and promoting brand legacies and
              treasured craftsmanship, to an ever-evolving client base.
            </p>
          </div>
        </div>
      </section>

      <section
        className="py-14  @sm:py-10 @sm:px-8"
        style={{ background: "radial-gradient(circle, #11115a, #0d0d3a)" }}
      >
        <div className="flex items-center justify-center">
          <div className="w-[80%]">
            <div className="flex items-center justify-center mb-12 @sm:hidden ">
              <div>
                <img
                  className="@lg:w-[1.4vw"
                  src="images/about/spear.svg"
                  alt="left border"
                />
              </div>
              <div className="border-1 border-white w-[25vw] ml-1"></div>
              <div className="mx-4 tracking-widest text-white fs-40">
                CHAIRMAN'S NOTE
              </div>
              <div className="border-1 border-white w-[25vw] mr-1"></div>
              <div>
                <img
                  className="@lg:w-[1.4vw rotate-180"
                  src="images/about/spear.svg"
                  alt="left border"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b-1 border-[#DBDBDB] pb-2 @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden">
          <div className="tracking-widest text-center text-white fs-40 fw-400">
            CHAIRMAN'S NOTE
          </div>
        </div>
        <Container className=" @sm:w-full w-[65%]">
          {/* @lg:px-32 @md:px-20 */}
          <div className="@sm:border-b-1 @sm:border-[#DBDBDB] @sm:py-4">
            <p className=" text-white text-center @sm:text-left">
              JMC was founded with the intention to help the luxury industry
              seamlessly navigate through ever-evolving market conditions and
              client behaviours. We are steeped in ethics to co-create
              excellence and deliver with integrity. Privileged to share our
              journey with some of the luxury industry's most dynamic leaders
              and brands, we look forward to constantly creating value by
              optimising business potential and building legacies.
            </p>
          </div>
          <img
            className="mx-auto mt-12 mb-8 @sm:mt-6 @sm:mb-0"
            src="images/about/signature.svg"
            alt="Tikka Singh"
          />
          <p className="text-center text-white @sm:hidden tracking-widest">
            TIKKA SINGH, FOUNDER AND CHAIRMAN
          </p>
        </Container>
      </section>

      <section className="my-20 @sm:mt-0 @sm:mb-10 @sm:px-0">
        <div className="flex items-center justify-center">
          <div className="w-[80%]">
            <div className="flex items-center justify-center mb-12 @sm:hidden ">
              <div>
                <img
                  className="@lg:w-[1.4vw] object-contain"
                  src="images/about/spear.svg"
                  alt="left border"
                />
              </div>
              <div className="border-1 border-[#DBDBDB] w-[33vw] ml-1"></div>
              <div className="fs-40 text-[#0b0b2f] mx-4 tracking-widest">
                LEADERSHIP
              </div>
              <div className="border-1 border-[#DBDBDB] w-[33vw] mr-1"></div>
              <div>
                <img
                  className="@lg:w-[1.4vw] rotate-180 object-contain"
                  src="images/about/spear.svg"
                  alt="left border"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-[80%] mx-auto @sm:pt-4 @sm:mx-0 @sm:w-full ">
          {data.map((item, index) => {
            return (
              <FadeIn>
                {" "}
                <AboutComp
                  key={index}
                  image={item.image}
                  alt={item.alt}
                  title={item.title}
                  description={item.description}
                  description2={item.description2}
                  description3={item.description3}
                  description4={item.description4}
                  description5={item.description5}
                  readLink={item.readLink}
                  modalImg={item.modalImg}
                  suryadescription4={item.suryadescription4}
                  index={index}
                />{" "}
              </FadeIn>
            );
          })}
        </div>
      </section>

      {/* <section className="mb-20 ">
        <div className="flex items-center justify-center">
          <div className="w-[80%]">
            <div className="flex items-center justify-between mb-12 @sm:hidden ">
              <div>
                <img
                  className="@lg:w-[1.4vw]"
                  src="images/about/spear.svg"
                  alt="left border"
                />
              </div>
              <div className="border-1 border-[#DBDBDB] w-[25vw] ml-1"></div>
              <div className="fs-40 text-[#0b0b2f] mx-4">ADVISORY BOARD</div>
              <div className="border-1 border-[#DBDBDB] w-[25vw] mr-1"></div>
              <div>
                <img
                  className="@lg:w-[1.4vw rotate-180"
                  src="images/about/spear.svg"
                  alt="left border"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-[80%] mx-auto @sm:w-full @sm:px-8">
          <div className="border-b-1 border-[#DBDBDB] pb-2 @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden @sm:px-8">
            <div className="fs-40 text-[#0b0b2f] mx-4 text-center tracking-widest">
              ADVISORY BOARD
            </div>
          </div>
          <div className="flex justify-between @sm:flex-col @sm:mt-8">
            {advisoryData.map((items, index) => {
              return (
                // <AdvisoryBoardComp
                //   key={index}
                //   image={item.image}
                //   alt={item.alt}
                //   name={item.name}
                //   description={item.description}
                //   index={index}
                // />

                <div
                  key={items.id}
                  style={
                    {
                      // backgroundImage: `url(${items.image})`,
                      // backgroundSize: "cover",
                      // backgroundRepeat: "no-repeat",
                      // // height: "500px",
                    }
                  }
                  className="group about-container-services  @sm:mb-3  bg-[#FFF] @sm:bg-[#F2F2F2] border-2 border-[#DBDBDB] border-b-0 @sm:border-0 @sm:border-b-[#707070] rounded-[7px] @sm:rounded-lg w-[30%] @lg:w-[30%] @sm:w-[100%] @sm:m-0 cursor-pointer "
                >
                  <div className="flex flex-col justify-center items-center  @sm:pt-0 @sm:pb-0 top-div-about ">
                    <div className="  @sm:py-0 @sm:pb-0 w-full ">
                      <div className="@sm:hidden ">
                        <img
                          src={items.image}
                          className="w-[100%] @sm:w-full @sm:object-cover"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                      <div className="@lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden">
                        <div className="bg-[#fff] flex flex-col justify-center items-center ">
                          <img
                            src={items.image}
                            className="w-full h-full"
                            style={{ objectFit: "contain" }}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" text-center h-[25vh] justify-center items-center @sm:pb-2 @sm:pt-0  rounded-md   about-p-tag w-full">
                      <p className="fs-28 text-[#11134e] pt-7 pb-2 border-b-2 mx-4 @sm:border-[#BCBCBC]">
                        {items.name}
                      </p>
                      <p className="w-3/4 pt-2 mx-auto ">{items.description}</p>
                    </div>
                    <div
                      className={`  w-full rounded-b-[7px] about-bottom-div  `}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section> */}
    </>
  );
};

export default About;
