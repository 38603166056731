import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "../screens/home-screen";
import About from "../screens/about";
import HeritageScreen from "../screens/heritage-screen";
import ServiceScreen from "../screens/service-screen";
import ContactScreen from "../screens/contact-screen";
import CareerScreen from "../screens/career-screen";
import AnalyticsService from "../screens/services_screens/analytics";
import BrandManagement from "../screens/services_screens/brand-management";
import ClientDevelopment from "../screens/services_screens/client";
import PerformanceManagement from "../screens/services_screens/performance-management";
import NewMarketEntry from "../screens/services_screens/new-market-entry";
import Client from "../screens/client";
import JmcScreen from "../screens/jmc-screen";
// import HeritageScreen2 from "../screens/heritage-screen2";
// import JmcScreen from "../screens/jmc-screen";

const PagesRoutes = () => {
  return (
    <>
      <Routes>
      <Route path="/" element={<JmcScreen/>}/>
        <Route path="/home" element={<HomeScreen />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/heritage" element={<HeritageScreen />} />
        <Route path="/service" element={<ServiceScreen />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/career" element={<CareerScreen />} />
        <Route path="/AnalyticsService" element={<AnalyticsService />} />
        <Route path="/BrandManagement" element={<BrandManagement />} />
        <Route path="/ClientDevelopment" element={<ClientDevelopment />} />
        <Route path="/NewMarketEntry" element={<NewMarketEntry />} />
        <Route path="/Client" element={<Client />} />
        <Route path="/PerformanceManagement" element={<PerformanceManagement />}
        />
        {/* <Route path="/HeritageScreen2" element={<HeritageScreen2 />} /> */}
      </Routes>
    </>
  );
};

export default PagesRoutes;
