import { Breadcrumbs } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ServicesDetails from "../../components/services-details";
import ServicesDetailsBox from "../../components/services-details-box";
import Pagination from "../../components/pagination";
import Lottie from "lottie-react";
import Magnifying from "../.././assets/Animation/1st Animation-Magnifying.json";
import MagnifyingMob from "../.././assets/Animation/1st Animation-Magnifying-mob.json";

const BoxData = [
  {
    id: 1,
    Img: "/images/services/analytic/1.png",
    Heading: "BRAND ANALYTICS",
    Disc: "Brand insights are the compass that guides luxury businesses towards strategic decisions and success. By analyzing consumer behaviour and feedback, market trends, and competitor analysis, we uncover valuable metrics to drive sustainable growth.",
  },
  {
    id: 2,
    Img: "/images/services/analytic/2.png",
    Heading: "MARKET RESEARCH",
    Disc: "Market research provides a comprehensive understanding of the ever-evolving market and it’s competitive landscape, enabling informed decision-making and strategic planning. We help clients to identify emerging opportunities, niche markets, refine their product offerings, and stay ahead of the curve in the dynamic world of luxury.",
  },
  {
    id: 3,
    Img: "/images/services/analytic/3.png",
    Heading: "CLIENT ANALYTICS",
    Disc: "By examining customer behaviours, preferences, and purchase patterns, we enable brands to personalize their offerings, optimize marketing efforts, enhance loyalty and cultivate long-lasting relationships with their clientele.",
  },
  {
    id: 4,
    Img: "/images/services/analytic/4.png",
    Heading: "BUSINESS INSIGHTS",
    Disc: "We provide a holistic view of the business operations, financial data and sales to assess performance, maximize profitability, and uncover potential opportunities for expansion.",
  },
];

const AnalyticsService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* breadcrumbs  */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6 text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#707070"
          to="/service"
          className="text-[#707070] fs-14"
        >
          SERVICES
        </Link>
        <Link
          underline="hover"
          color="#11134E"
          href="/AnalyticsService"
          className="text-[#11134E] fs-14"
        >
          ANALYTICS & INSIGHTS
        </Link>
      </Breadcrumbs>

      <>
        <ServicesDetails
          MobHeading={"ANALYTICS & INSIGHTS"}
          Heading={"ANALYTICS & INSIGHTS"}
          SubHeading={"Empowering Intuition With Data"}
          Description={`In today's rapidly evolving business landscape, data analysis plays a pivotal role in driving growth and gaining a competitive edge.
          JMC’s Insights and Analytics service is designed to transform raw data into actionable intelligence, unlocking trends and opportunities that will propel your business in unexpected ways.<br/><br/>Our big data analysts and business consultants combine technical expertise and data science with deep industry knowledge to identify growth channels, evaluate performance metrics, and detect valuable insights which are personalised to your commercial objectives and brand vision.<br/><br/>We don’t just inform, we inspire!`}
        />
      </>

      <div className="">
        {window.innerWidth >= 768 ? (
          <Lottie animationData={Magnifying} />
        ) : (
          <Lottie animationData={MagnifyingMob} />
        )}
      </div>

      <div className=" p-5">
        <ServicesDetailsBox BoxData={BoxData} />
      </div>

      <div className="pt-10 pb-20">
        <Pagination
          prevLink={"/service"}
          prevPage={"BACK TO SERVICES"}
          nextPage={"BRAND MANAGEMENT"}
          nextLink={"/BrandManagement"}
          mobHeading={"ANALYTICS & INSIGHTS"}
        />
      </div>
    </div>
  );
};

export default AnalyticsService;
