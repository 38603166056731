import { Breadcrumbs } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ServicesDetails from "../components/services-details";

const logoData = [
  {
    id: 1,
    img: "/images/client/1.png",
    category: "fashion",
  },
  {
    id: 2,
    img: "/images/client/2.png",
    category: "fashion",
  },
  {
    id: 3,
    img: "/images/client/3.png",
    category: "",
  },
  {
    id: 4,
    img: "/images/client/4.png",
    category: "fashion",
  },
  {
    id: 5,
    img: "/images/client/5.png",
    category: "wine",
  },
  {
    id: 6,
    img: "/images/client/6.png",
    category: "fashion",
  },
  {
    id: 7,
    img: "/images/client/7.png",
    category: "",
  },
  {
    id: 8,
    img: "/images/client/8.png",
    category: "fashion",
  },
  {
    id: 9,
    img: "/images/client/9.png",
    category: "automotive",
  },
  {
    id: 10,
    img: "/images/client/10.png",
    category: "fashion",
  },
  {
    id: 11,
    img: "/images/client/11.png",
    category: "realestate",
  },

  {
    id: 12,
    img: "/images/client/12.png",
    category: "fashion",
  },
  {
    id: 13,
    img: "/images/client/13.png",
    category: "fashion",
  },
  {
    id: 14,
    img: "/images/client/14.png",
    category: "fashion",
  },
  {
    id: 15,
    img: "/images/client/15.png",
    category: "fashion",
  },
  {
    id: 16,
    img: "/images/client/16.png",
    category: "fashion",
  },
  {
    id: 17,
    img: "/images/client/17.png",
    category: "fashion",
  },

  {
    id: 18,
    img: "/images/client/18.png",
    category: "watches",
  },
  {
    id: 19,
    img: "/images/client/19.png",
    category: "aviation",
  },
  {
    id: 20,
    img: "/images/client/20.png",
    category: "fashion",
  },
  {
    id: 21,
    img: "/images/client/21.png",
    category: "aviation",
  },
  {
    id: 22,
    img: "/images/client/22.png",
    category: "wine",
  },
  {
    id: 23,
    img: "/images/client/23.png",
    category: "wine",
  },
  {
    id: 24,
    img: "/images/client/24.png",
    category: "wine",
  },
  {
    id: 25,
    img: "/images/client/25.png",
    category: "wine",
  },
  {
    id: 26,
    img: "/images/client/26.png",
    category: "fashion",
  },
  {
    id: 27,
    img: "/images/client/27.png",
    category: "fashion",
  },
  {
    id: 28,
    img: "/images/client/28.png",
    category: "fashion",
  },
  {
    id: 29,
    img: "/images/client/29.png",
    category: "automotive",
  },
  {
    id: 30,
    img: "/images/client/30.png",
    category: "fashion",
  },
];

const btnName = [
  {
    id: 1,
    btnName: "ALL",
  },
  {
    id: 2,
    btnName: "AVIATION",
    category: "aviation",
  },
  {
    id: 3,
    btnName: "AUTOMOTIVE",
    category: "automotive",
  },
  {
    id: 4,
    btnName: "RETAIL, FASHION & ACCESSORIES",
    category: "fashion",
  },
  {
    id: 5,
    btnName: " WINES & SPIRITS ",
    category: "wine",
  },
  {
    id: 6,
    btnName: "REAL ESTATE",
    category: "realestate",
  },
  {
    id: 7,
    btnName: " WATCHES AND JEWELRY",
    category: "watches",
  },
];

const Client = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [data, setdata] = useState(logoData);
  const listRef = useRef(0);
  const toggleSelection = (index, categoryitem) => {
    setSelectedItem(index === selectedItem ? selectedItem : index);
    if (categoryitem) {
      const updateditems = logoData.filter((currelem) => {
        return currelem.category === categoryitem;
      });
      setdata(updateditems);
    } else {
      setdata(logoData);
    }
  };

  const nextstate = (nextdata) => {
    if (nextdata) {
      const updateditemsnext = logoData.filter((currelem) => {
        return currelem.category === nextdata;
      });
      setdata(updateditemsnext);
    } else {
      setdata(logoData);
    }
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const list = listRef.current;
  //     const itemWidth = list.offsetWidth;
  //     const scrollPosition = list.scrollLeft;

  //     // Calculate the index of the visible item based on the scroll position
  //     const visibleItemIndex = Math.round(scrollPosition / itemWidth);

  //     setSelectedItem(visibleItemIndex);
  //   };

  //   listRef.current.addEventListener("scroll", handleScroll);

  //   return () => {
  //     listRef.current.removeEventListener("scroll", handleScroll);
  //   };
  // }, [listRef]);

  useEffect(() => {
    const handleScroll = () => {
      const list = listRef.current;
      if (!list) return; // Null check

      const itemWidth = list.offsetWidth;
      const scrollPosition = list.scrollLeft;

      // Calculate the index of the visible item based on the scroll position
      const visibleItemIndex = Math.round(scrollPosition / itemWidth);

      setSelectedItem(visibleItemIndex);

      // Get the corresponding category based on the visible item index
      const category = btnName[visibleItemIndex].category;

      // Filter the logoData based on the category
      const updatedItems = logoData.filter((item) => {
        return category ? item.category === category : true;
      });

      // Update the data state with the filtered items
      setdata(updatedItems);
    };

    const list = listRef.current;
    if (list) {
      list.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (list) {
        list.removeEventListener("scroll", handleScroll);
      }
    };
  }, [listRef, btnName]);

  const handleNext = () => {
    const nextItem = selectedItem === btnName.length - 1 ? 0 : selectedItem + 1;
    if (nextItem === 1) {
      nextstate("aviation");
    } else if (nextItem === 2) {
      nextstate("automotive");
    } else if (nextItem === 3) {
      nextstate("fashion");
    } else if (nextItem === 4) {
      nextstate("wine");
    } else if (nextItem === 5) {
      nextstate("realestate");
    } else if (nextItem === 6) {
      nextstate("watches");
    } else if (nextItem === 0) {
      nextstate();
    }
    setSelectedItem(nextItem);
    scrollToListItem(nextItem);
  };

  const handlePrev = () => {
    const prevItem = selectedItem === 0 ? btnName.length - 1 : selectedItem - 1;
    if (prevItem === 1) {
      nextstate("aviation");
    } else if (prevItem === 2) {
      nextstate("automotive");
    } else if (prevItem === 3) {
      nextstate("fashion");
    } else if (prevItem === 4) {
      nextstate("wine");
    } else if (prevItem === 5) {
      nextstate("realestate");
    } else if (prevItem === 6) {
      nextstate("watches");
    } else if (prevItem === 0) {
      nextstate();
    }
    setSelectedItem(prevItem);
    scrollToListItem(prevItem);
  };

  const scrollToListItem = (itemIndex) => {
    if (listRef.current) {
      const list = listRef.current;
      const selectedElement = list.children[itemIndex];

      // Calculate the scroll position to center the selected element
      const scrollPosition =
        selectedElement.offsetLeft -
        (list.offsetWidth - selectedElement.offsetWidth) / 2;

      list.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      {" "}
      {/* breadcrumbs  */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6 text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#707070"
          to="/Client"
          className="text-[#11134E] fs-14"
        >
          CLIENTS
        </Link>
      </Breadcrumbs>
      <>
        <ServicesDetails
          DescriptionTopPadding={"pt-1"}
          HeadingWidth={"w-[37%] @lg:w-[37%] @xl:w-[37%] @2xl:w-[37%]"}
          MobHeading={"CLIENTS"}
          Heading={"CLIENTS"}
          //   SubHeading={"Adapting..."}
          Description={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar ornare leo a aliquet. Cras at sapien eget massa dapibus volutpat. Morbi ac accumsan tellus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. `}
        />
      </>
      <div className="w-full  px-20 @sm:px-5 @sm:flex-col  my-10 flex justify-between items-start gap-10 ">
        <div className="w-[70%] @sm:w-full @sm:order-1">
          <div className="grid grid-cols-3  @sm:gap-4">
            {data.map((item, index, array) => {
              return (
                <>
                  <div
                    className={`w-[100%] h-[20vh] @sm:h-[10vh]  flex justify-center items-center  border-[#D8D8D8] @sm:rounded-md gap-6 ${
                      index % 3 === 2
                        ? "border-r-0 @sm:border-r-0"
                        : "border-r-2 @sm:border-r-0"
                    } ${
                      array.length - index <= 3
                        ? "border-b-0"
                        : "border-b-2 @sm:border-b-0"
                    }`}
                  >
                    <div className="logoHover h-[18vh] p-5 @sm:h-full w-[95%] @sm:w-[100%] flex justify-center items-center rounded-lg ">
                      <img
                        className="@sm:w-full @sm:h-10 "
                        style={{ objectFit: "contain" }}
                        src={item.img}
                        alt="brands"
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="w-[30%] @sm:w-[100%] sticky top-[10%] @sm:relative">
          <div className="flex justify-between items-center @sm:w-full">
            <button
              className="@lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden w-20"
              onClick={handlePrev}
            >
              <img
                className="w-5 h-5"
                src="/images/common/back.png"
                style={{ objectFit: "contain" }}
                alt="brands"
              />
            </button>
            <ul ref={listRef} className=" mobile-list">
              {btnName.map((btnItem, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => toggleSelection(index, btnItem.category)}
                    className={`cursor-pointer mobile-list-item ${
                      index === selectedItem
                        ? "border-l-[6px]  border-[#0B0B2F] fs-24 @sm:text-[18px] @sm:justify-center @sm:items-center @sm:flex text-[#0B0B2F] @sm:text-center @sm:border-0 tracking-wider @sm:border-y-2 @sm:border-[#DBDBDB]"
                        : " fs-24 @sm:text-[18px] @sm:justify-center @sm:items-center @sm:flex text-[#D8D8D8] border-l-2 border-[#F5F5F5] @sm:text-center @sm:border-0 tracking-wider @sm:border-y-2 @sm:border-[#DBDBDB]"
                    } p-4`}
                  >
                    {btnItem.btnName}
                  </li>
                );
              })}
            </ul>

            <button
              className="@lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden w-20 "
              onClick={handleNext}
            >
              <img
                className="w-5 h-5"
                src="/images/common/forward.png"
                style={{ objectFit: "contain" }}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
