import { Breadcrumbs } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ServicesDetails from "../../components/services-details";
import ServicesDetailsBox from "../../components/services-details-box";
import Pagination from "../../components/pagination";
import Lottie from "lottie-react";
import Brandmanagement from "../.././assets/Animation/Brand-Management-Wide.json";
import BrandmanagementMob from "../.././assets/Animation/Brand-Management-Wide-mob.json";

const BoxData = [
  {
    id: 1,
    Img: "/images/services/brand/2.png",
    Heading: "BRAND DESIGN",
    Disc: "We create visually compelling and cohesive brand identities, encompassing logo design, typography, color palette, and other visual assets, to create a memorable brand experience / to consistently reflect your brand's personality and resonate with your target audience, across all touchpoints.",
  },
  {
    id: 2,
    Img: "/images/services/brand/3.png",
    Heading: "BRAND STRATEGY",
    Disc: `We define and articulate your brand's unique value proposition, values, and personality, through a comprehensive brand strategy that sets the foundation for all subsequent brand-related activities.
    We focus on aligning business objectives with suitable market positioning and consistent messaging, to build brand equity based on authenticity and [ ?]`,
  },
  {
    id: 3,
    Img: "/images/services/brand/1.png",
    Heading: "BRAND MARKETING",
    Disc: "We develop tailored marketing strategies and campaigns to increase brand awareness, drive customer engagement, and generate brand loyalty. From traditional advertising to digital marketing, we craft compelling narratives and leverage the most effective channels to reach target audience, shape perception and create meaningful connections.",
  },
];

const BrandManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* breadcrumbs  */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6 text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#707070"
          to="/service"
          className="text-[#707070] fs-14"
        >
          SERVICES
        </Link>
        <Link
          underline="hover"
          color="#11134E"
          href="/AnalyticsService"
          className="text-[#11134E] fs-14"
        >
          BRAND MANAGEMENT
        </Link>
      </Breadcrumbs>

      <>
        <ServicesDetails
          MobHeading={"BRAND MANAGEMENT"}
          Heading={"BRAND MANAGEMENT"}
          SubHeading={"Navigating the Path to Brand Excellence"}
          Description={`A brand goes beyond a logo or product, it is a set of expectations, memories, stories and relationships that unfold and evolve at every touchpoint of the client journey.<br/><br/>

          JMC’s Brand Management service delivers a comprehensive strategy which aligns your brand’s vision and values with commercial objectives.<br/><br/>
          
          We set an authoritative blueprint which defines your brand’s positioning, amplifies core attributes and identifies client needs and preferences to provide targeted navigation, engagement and reassurance strategies which consistently win the client and spur growth.<br/><br/>
          
          Our expertise in brand design, strategy, and marketing will help you launch (and maintain) a distinguishable presence which resonates with your target audience and is cherished for generations to come.`}
        />
      </>

      <div className="">
        {window.innerWidth >= 768 ? (
          <Lottie animationData={Brandmanagement} />
        ) : (
          <Lottie animationData={BrandmanagementMob} />
        )}
      </div>

      <div className="p-5">
        <ServicesDetailsBox BoxData={BoxData} />
      </div>
      <div className="pt-10 pb-20">
        <Pagination
          prevLink={"/AnalyticsService"}
          prevPage={"BACK TO ANALYTICS & INSIGHTS"}
          nextPage={"CLIENT DEVELOPMENT"}
          nextLink={"/ClientDevelopment"}
          mobHeading={"BRAND MANAGEMENT"}
        />
      </div>
    </div>
  );
};

export default BrandManagement;
