import { Breadcrumbs } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const data = [
  {
    image: "images/services/analytics.png",
    name: "ANALYTICS & INSIGHTS",
    id: 1,
    route: "/AnalyticsService",
  },
  {
    image: "images/services/brand.png",
    name: "BRAND MANAGEMENT",
    id: 2,
    route: "/BrandManagement",
  },
  {
    image: "images/services/client.png",
    name: "CLIENT DEVELOPMENT",
    id: 3,
    route: "/ClientDevelopment",
  },
  {
    image: "images/services/performance.png",
    name: "PERFORMANCE MANAGEMENT",
    id: 4,
    route: "/PerformanceManagement",
  },
  {
    image: "images/services/market.png",
    name: "NEW MARKET ENTRY",
    id: 5,
    route: "/NewMarketEntry",
  },
];

const ServiceScreen = () => {
  const [hovered, setHovered] = useState(Array(data.length).fill(false));

  const navigate = useNavigate();
  return (
    <div className="pb-12 @sm:pb-0">
      {/* breadcrumbs  */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6 text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#11134E"
          href="/service"
          className="text-[#11134E] fs-14"
        >
          SERVICES
        </Link>
      </Breadcrumbs>

      {/* section 1 */}
      <div className="pt-14 px-48 @sm:px-10 @sm:pt-8 @md:px-24 @2xl:px-60">
        <section className=" mb-10 @sm:mb-8 ">
          {/* desktop heading  */}
          <div className="flex items-center justify-center mb-7 @sm:hidden">
            <div className="">
              <img
                className="@lg:w-[1.4vw]"
                src="images/about/spear.svg"
                alt="left border"
              />
            </div>
            <div className="border-1 border-[#DBDBDB] w-[36vw] ml-1"></div>
            <div className="fs-40 text-[#0b0b2f] mx-4 tracking-widest">
              SERVICES
            </div>
            <div className="border-1 border-[#DBDBDB] w-[36vw] mr-1"></div>
            <div className="">
              <img
                className="@lg:w-[1.4vw]  rotate-180"
                src="images/about/spear.svg"
                alt="left border"
              />
            </div>
          </div>
          {/* mobile heading  */}
          <div className="border-b-1 border-[#DBDBDB] @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden @sm:mb-4 ">
            <div className="pb-1 tracking-widest text-center fs-40 fw-400">
              SERVICES
            </div>
          </div>
          {/* description  */}
          <div className="px-48 @sm:px-0 @md:px-40 flex flex-col justify-center ">
            <p className="fs-16 fw-400 text-[#545454] text-center @sm:text-left">
              Our data-driven and client-centric approach is the foundation for
              a suite of services and commercial strategies, which we
              personalize to support and manage brands, clients, sales
              performance and new market expansions.
            </p>
          </div>
        </section>
      </div>

      {/* section 2 */}
      <section className="px-10 py-4 @sm:px-0 @sm:pb-0">
        <div className="flex flex-row justify-evenly  @sm:flex-wrap @sm:items-center @sm:justify-center @sm:mb-5">
          {data.map((items, index) => {
            const handleClicked = () => {
              console.log("clicked", items);
              navigate(items.route);
            };
            return (
              <>
                <div
                  // className="w-5 px-10"
                  onClick={handleClicked}
                  key={items.id}
                  className="group container-services @sm:mb-3  bg-[#fff]  border-2 border-[#DBDBDB] border-b-0 @sm:border-b-[#707070] rounded-[7px]  w-[17%] @lg:w-[18%] @sm:w-[40%] @sm:m-2 cursor-pointer "
                >
                  <div className="flex flex-col justify-center  items-center @sm:pb-5 top-div ">
                    <div className=" py-[6vh] @sm:py-0 @lg:py-[4vh] @sm:pb-2 ">
                      <div className="">
                        <img
                          alt=""
                          src={items.image}
                          className="h-40 w-40 @sm:w-28 @sm:h-40  "
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                      <div className="@sm:hidden @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden">
                        <div className="bg-[#fff] flex flex-col justify-center items-center p-2">
                          <img
                            alt=""
                            src={items.image}
                            className="w-28 h-28"
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" text-center pt-3 pb-7 @sm:pb-2 @sm:pt-0  p-tag">
                      <p className=" fs-24 fw-400 tracking-widest mb-3 @sm:mb-0   px-8 @sm:px-3  @sm:text-[#0B0B2F] ">
                        {items.name}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`  w-full rounded-b-[7px]  bottom-div  `}
                  ></div>
                </div>
              </>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default ServiceScreen;
