import { Breadcrumbs } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ServicesDetails from "../../components/services-details";
import ServicesDetailsBox from "../../components/services-details-box";
import Pagination from "../../components/pagination";

const BoxData = [
  {
    id: 1,
    Img: "/images/services/new-market/2.png",
    Heading: "ASSESSMENT",
    Disc: `To improve and ensure exceptional outcomes of a new market entry and reduce the odds of failure, a brand must thoroughly investigate the entire new ecosystem via an independent and ethical party.
<br/>This analysis includes: a multi-city competitive performance, homegrown retail understanding and an overall cultural product fit evaluation. JMC tailors solutions particular to a brand's company structure and global brand goals.    `,
  },
  {
    id: 2,
    Img: "/images/services/new-market/1.png",
    Heading: "FEASABILITY",
    Disc: `With the global economy being exceedingly volatile and consumer pools growing in unexpected locations, spreading brand reach helps sustain commercial growth. With extensive understanding of local compliances, an unparalleled knowledge and sensitivity of cultural nuance, JMC offers a robust localization plan to better integrate into the economy, solve operational peculiarities, finding solutions for operational and administrative challenges.
<br/>We do this by balancing homeland headquarter mandates with a ground level understanding of local consumption that reflects the active luxury landscape.    `,
  },
  {
    id: 3,
    Img: "/images/services/new-market/3.png",
    Heading: "IMPLEMENTATION",
    Disc: `Is the final and most crucial step of a new market entry. We have focused on developing customized solutions and active partner network to equip our clients with actionable strategy which enables them to smoothly launch and maintain an agile brand in a foreign territory.
<br/>
This includes supervision of retail development, overseeing the management of partners to help amplify the inaugural launch and on to the maintenance of a brand's strategic calendar to sustainably grow its presence.`,
  },
];

const NewMarketEntry = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* breadcrumbs  */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6 text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#707070"
          to="/service"
          className="text-[#707070] fs-14"
        >
          SERVICES
        </Link>
        <Link
          underline="hover"
          color="#11134E"
          href="/AnalyticsService"
          className="text-[#11134E] fs-14"
        >
          NEW MARKET EXPANSION
        </Link>
      </Breadcrumbs>

      <>
        <ServicesDetails
          HeadingWidth={"w-[80%]"}
          HeadingTxtWidth={"@lg:w-[60%]"}
          MobHeading={"NEW MARKET EXPANSION"}
          Heading={"NEW MARKET EXPANSION"}
          SubHeading={"Adapting..."}
          Description={`When expanding into a new territory each brand requires a unique partner or structure that is particular to their business operation, heritage and new regions economy.
          <br/><br/>

          Entering a new market for a brand is always exciting as there are many benefits to capture, however overlooking the operational, political, social and economic variables can hinder the possibility to fully maximise the business opportunity.
<br/><br/>

New market expansions often require local guidance and expertise to have the most stable and profitable entry. We believe that brands require a tailored, culturally sensitive and strategic approach personalised to their goals and competitive landscape.
<br/><br/>
JMC’s approach involves dedicated guidance through the three key phases of Assessment, Feasibility and Implementation by mapping out a meticulous New Market Expansion based on industry-agnostic and proven frameworks.
<br/><br/>
Our detailed assessment and feasibility plans help guide brands through implementation by providing hands-on support in setting up operations, establishing distribution networks, and building strategic partnerships with local stakeholders, including recruitment and training local teams.

`}
        />
      </>

      <div className="">
        <video
          autoPlay
          loop
          muted
          preload="auto"
          playsInline
          className="w-auto h-auto @sm:h-[40vh] @sm:w-[100%] "
          style={{ objectFit: "cover", objectPosition: "center center" }}
          // controls
        >
          {window.innerWidth >= 768 ? (
            <source
              src="/images/services/brand/Globe-3d-Low.mp4"
              type="video/webm"
            />
          ) : (
            <source
              src="/images/services/brand/Globe-Mobile-Low.mp4"
              type="video/webm"
            />
          )}
        </video>
      </div>

      <div className="p-5">
        <ServicesDetailsBox BoxData={BoxData} />
      </div>
      <div className="pt-10 pb-20">
        <Pagination
          prevLink={"/PerformanceManagement"}
          prevPage={"BACK TO PERFORMANCE MANAGEMENT"}
          nextPage={"CONTACT"}
          nextLink={"/contact"}
          mobHeading={"PERFORMANCE MANAGEMENT"}
        />
      </div>
    </div>
  );
};

export default NewMarketEntry;
