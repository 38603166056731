import { Breadcrumbs } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Categories from "../components/heritage/categories";
import Modal from "@mui/material/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// const categoryData = [
//   {
//     id: 1,

//     leftImage: "images/heritage/crest.png",
//     rightHeading: "CREST",
//     rightDescription1:
//       " Embodying the motto and ethos of the Kapurthala state, this majestic Crest evolved along with Maharaja Jagajit Singh and the state. He directed modifications and adjustments of the graphic design and typography in line with his progressive reign and how he wanted to project / represent the dynamic dominion.",
//     rightDescription2:
//       " To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began.",
//     leftHeading: "CHATEAU",
//     leftDescription1:
//       "Following a winding road up the Xx hills of Mussoorie, tucked away behind majestic Deodar trees, is the summer abode of Maharaja of Kapurthala descendant of Tikka Singh founder of JMC.",
//     leftDescription2:
//       "This wondrous chateau, serves as a meaningful symbol for JMC: as the eclectic backdrop where lasting relationships were formed, the highest forms of experiential curation was extended and the most distinctive branded moments delighted all those who walked through its grounds and stayed within its chambers those included many of [the makers and designers of the items you see on the heritage page….eg. Jacques Cartier, etc.]",
//     leftDescription3:
//       "Going beyond just being a warm host, Jagatjit Singh went into the details of decoration, activity, agendas, so that each guests stay always retained his own personal touch, this indicates his style of curation, branding and extension of cultural storytelling that left an indelible mark. ",
//     rightImage: "images/heritage/chateau.png",
//     modelImg: "images/heritage/CREST-01.png",
//   },
//   {
//     id: 2,

//     leftImage: "images/heritage/patek.png",
//     rightHeading: "PATEK PHILIPPE POCKET WATCH",
//     rightDescription1: `An testament to the brands tagline "...one never owns a Patek Phillipe you merely look after it for the next generation". This cherished possession displays his personal association and respect from the maker: on the reverse is a hand-crafted engraving that merges the initials of Maharaja of Kapurthala with PP of Patek Philippe. A branding vision way ahead of its time.`,
//     rightSubHeading: "(CA, 1877)",
//     rightImage: "images/heritage/bacarrat.png",
//     leftHeading: "BACCARAT (CA, INSERT YEAR)",
//     leftDescription1: "Hand carved crystal serveware and glasses.",
//     leftDescription2:
//       "His connoisseurship for luxury and attention to detail of hosting and service, made him an highly regarded client by Baccarat, as evident in elaborate engraving of his initials on commissioned pieces. This added a personalized touch to the experience for his guests.",
//     modelImg: "images/heritage/2nd-model.png",
//   },
//   {
//     id: 3,

//     rightImage: "images/heritage/cartier.png",
//     rightHeading: "LOUIS VUITTON TRUNKS (CA, 1837)",
//     rightDescription1: "One of 50",
//     rightDescription2:
//       "These were commissioned and tailored to his specific needs. These were as much a reflection of his discerning eye for luxury as it was for branding. While monogramming luxury goods is still a special service 100 years ago, Maharaja Jagajit Singh [spearheaded] was one of the first recipients of the format that could personalize his possessions with his state colours and identity that we have also inherited. Discernable is our use of Royal Navy Blue and White.",
//     leftImage: "images/heritage/louis.png",
//     leftHeading: "CARTIER TURBAN ORNAMENT (CA, 1926)",
//     leftDescription1:
//       "This features nineteen emeralds in varying sizes and shapes, the largest hexagonal emerald weighing 117.40 carats  and numerous pearls and white diamonds for accent. ",
//     leftDescription2:
//       "A testament of his co-creation style that would mark his reign: the emeralds belonged to the vast treasury of the Maharajah, who commissioned Cartier to reset them in this exquisite modernized turban ornament. He sketched the famous pagoda style tiara by Cartier for his turban (that won accolades internationally.) ",
//     leftDescription3:
//       "The design was pure Orientalism, a definite departure from the Art Deco style Cartier was known for during the 1920s, which only reinforces the avid interest he took in the design process of the objects he commissioned. ",
//   },
//   {
//     id: 4,

//     leftImage: "images/heritage/brooch.png",
//     rightHeading: "CARTIER CORSAGE BROOCH (CA. 1905)",
//     rightDescription1: `One of many tokens of an unique romance, Maharaja Jagjit Singh, gifted this Brooch composed of a Belle Époque emerald and natural diamonds, as offering celestial emblem to his Maharani as a sign of his appreciation for her efforts to integrate into Indian culture. “Now you can have the moon…" His adoration of timelessly symbolic items gestures towards his understanding of branding, leaving unforgettable legendary memories in the hands of recipes.`,
//     rightImage: "images/heritage/moet.png",
//     leftHeading: "MOET CHANDON (CA, INSERT YEAR)",
//     leftDescription1:
//       "For over 100 years, was Moet & Chandon’s largest client. ",
//     leftDescription2:
//       "Genuine mutual admiration marked the relationship [that flourished to becoming close friends]. Maharaja of Kapurthala as a connoisseur was curious to understand the making of champagne, and Claude Moet, impressed by his keenness, and attention to detail and service, opened their House to him. [Do we want to get into quantity / variation] Even today our association with Moet [spans…]",
//   },
//   {
//     id: 5,

//     leftImage: "images/heritage/dits.png",
//     rightHeading: "MELLERIO DITS MELLER, (CA,1905) ",
//     rightDescription1:
//       "An Antique Diamond and Enamel Aigrette made with Rose-cut diamonds, blue, green, yellow and golden brown enamel, 18k gold (French marks), ins., 1905, signed Mellerio dit Meller, '9 Rue de la Paix, Paris'    ",
//     rightDescription2:
//       "The Maharaja of Kapurthala, found  this magnificent enamel and diamond peacock aigrette  unique piece at Mellerio, notably in India it is an auspicious mythical animal venerated world over. The Maharaja was an important client of the jewelry houses of Place Vendôme and Rue de la Paix where he frequented to amass a truly unique collection of spectacular pieces of craftsmanship.",
//     rightImage: "images/heritage/tank-watch.png",
//     leftHeading: "CARTIER TANK WATCH (CA, 1930)",
//     leftDescription1:
//       "Donec feugiat in ipsum sagittis egestas. Ut congue ipsum nec nisi efficitur dictum. Integer varius egestas pharetra. Sed in elementum est, in rutrum lorem. Cras tempor euismod faucibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer tempus nunc at elementum laoreet. Nulla vitae semper lectus, id porta tortor. Nam lobortis vel dui in semper.",
//   },
// ];

const BoxData = [
  {
    id: 1,
    // Img: "/images/heritage/crest.png",
    Img: [
      // "/images/heritage/crest.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch1.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch.png",
      "images/heritage/CREST_02.webp",
      "images/heritage/CREST-01.webp",
    ],
    Heading: "CREST",
    Disc: ` Embodying the motto and ethos of the Kapurthala state, this majestic Crest evolved along with Maharaja Jagajit Singh and the state. He directed modifications and adjustments of the graphic design and typography in line with his progressive reign and how he wanted to project / represent the dynamic dominion.<br/><br/>
 
    To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began.

   
    
    `,
  },
  {
    id: 2,
    // Img: "images/heritage/chateau.png",
    Img: [
      // "images/heritage/chateau.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch.png",
      "images/heritage/chateau_1.webp",
      "images/heritage/chateau_2.webp",
    ],
    Heading: "CHATEAU",
    Disc: `Following a winding road up the Xx hills of Mussoorie, tucked away behind majestic Deodar trees, is the summer abode of Maharaja of Kapurthala descendant of Tikka Singh founder of JMC.<br/><br/>

    This wondrous chateau, serves as a meaningful symbol for JMC: as the eclectic backdrop where lasting relationships were formed, the highest forms of experiential curation was extended and the most distinctive branded moments delighted all those who walked through its grounds and stayed within its chambers those included many of [the makers and designers of the items you see on the heritage page….eg. Jacques Cartier, etc.]<br/><br/>
    
    Going beyond just being a warm host, Jagatjit Singh went into the details of decoration, activity, agendas, so that each guests stay always retained his own personal touch, this indicates his style of curation, branding and extension of cultural storytelling that left an indelible mark. `,
  },
  {
    id: 3,
    // Img: "images/heritage/patek.png",
    Img: [
      // "images/heritage/patek.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch.png",
      "images/heritage/philipe-watch-1.webp",
      "images/heritage/philipe-watch-2.webp",
    ],
    Heading: `PATEK PHILIPPE POCKET WATCH <br/> (CA, 1877)`,
    Disc: `An testament to the brands tagline "...one never owns a Patek Phillipe you merely look after it for the next generation". This cherished possession displays his personal association and respect from the maker: on the reverse is a hand-crafted engraving that merges the initials of Maharaja of Kapurthala with PP of Patek Philippe. A branding vision way ahead of its time.`,
  },
  {
    id: 4,
    // Img: "images/heritage/bacarrat.png",
    Img: [
      // "images/heritage/bacarrat.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch.png",
      // "images/heritage/placeholder.png",
      "images/heritage/baccarat-1.webp",
      "images/heritage/baccarat-2.webp",
    ],
    Heading: "BACCARAT (CA, INSERT YEAR)",
    Disc: `Hand carved crystal serveware and glasses <br/> <br/>
    His connoisseurship for luxury and attention to detail of hosting and service, made him an highly regarded client by Baccarat, as evident in elaborate engraving of his initials on commissioned pieces. This added a personalized touch to the experience for his guests.`,
  },
  {
    id: 5,
    // Img: "images/heritage/louis.png",
    Img: [
      // "images/heritage/louis.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch.png",
      // "images/heritage/placeholder.png",
      "images/heritage/LV-trunk-1.webp",
      "images/heritage/LV-trunk-2.webp",
      "images/heritage/LV-trunk-3.webp",
      "images/heritage/LV-trunk-4.webp",
    ],
    Heading: "LOUIS VUITTON TRUNKS (CA, 1837)",
    Disc: `One of 50<br/> <br/>

    These were commissioned and tailored to his specific needs. These were as much a reflection of his discerning eye for luxury as it was for branding. While monogramming luxury goods was still a special service 100 years ago, Maharaja Jagajit Singh was one of the first recipients of the format that could personalize his possessions with his state colours and identity that we have also inherited. Discernable is our use of Royal Navy Blue and White.`,
  },
  {
    id: 6,
    // Img: "images/heritage/cartier.png",
    Img: ["images/heritage/cartier-turban-ornament-1.webp"],
    Heading: "CARTIER TURBAN ORNAMENT (CA, 1926)",
    Disc: `This features nineteen emeralds in varying sizes and shapes, the largest hexagonal emerald weighing 117.40 carats  and numerous pearls and white diamonds for accent.<br/> <br/> 

    A testament of his co-creation style that would mark his reign: the emeralds belonged to the vast treasury of the Maharajah, who commissioned Cartier to reset them in this exquisite modernized turban ornament. He sketched the famous pagoda style tiara by Cartier for his turban (that won accolades internationally.)<br/> <br/> 
    
    The design was pure Orientalism, a definite departure from the Art Deco style Cartier was known for during the 1920s, which only reinforces the avid interest he took in the design process of the objects he commissioned. `,
  },
  {
    id: 7,
    // Img: "images/heritage/brooch.png",
    Img: [
      // "images/heritage/brooch.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch.png",
      // "images/heritage/placeholder.png",
      "images/heritage/cartier-corsage-1.webp",
    ],
    Heading: "CARTIER CORSAGE BROOCH (CA. 1905)",
    Disc: `One of many tokens of an unique romance, Maharaja Jagjit Singh, gifted this Brooch composed of a Belle Époque emerald and natural diamonds, as offering celestial emblem to his Maharani as a sign of his appreciation for her efforts to integrate into Indian culture. “Now you can have the moon…" His adoration of timelessly symbolic items gestures towards his understanding of branding, leaving unforgettable legendary memories in the hands of recipes.`,
  },
  {
    id: 8,
    // Img: "images/heritage/moet.png",
    Img: [
      // "images/heritage/moet.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch.png",
      // "images/heritage/placeholder.png",
      "images/heritage/moet-chandon-1.webp",
      "images/heritage/moet-chandon-2.webp",
    ],
    Heading: "MOET CHANDON (CA, INSERT YEAR)",
    Disc: `For over 100 years, was Moet & Chandon’s largest client. <br/><br/>

    Genuine mutual admiration marked the relationship [that flourished to becoming close friends]. Maharaja of Kapurthala as a connoisseur was curious to understand the making of champagne, and Claude Moet, impressed by his keenness, and attention to detail and service, opened their House to him. [Do we want to get into quantity / variation] Even today our association with Moet [spans…]`,
  },
  {
    id: 9,
    // Img: "images/heritage/dits.png",
    Img: [
      // "images/heritage/dits.png",
      // "images/heritage/placeholder.png",
      // "images/heritage/tank-watch.png",
      // "images/heritage/placeholder.png",
      "images/heritage/MELLERIO-DITS-MELLER.webp",
    ],
    Heading: "MELLERIO DITS MELLER, (CA,1905) ",
    Disc: `An Antique Diamond and Enamel Aigrette made with Rose-cut diamonds, blue, green, yellow and golden brown enamel, 18k gold (French marks), ins., 1905, signed Mellerio dit Meller, '9 Rue de la Paix, Paris'<br/><br/>

    The Maharaja of Kapurthala, found  this magnificent enamel and diamond peacock aigrette  unique piece at Mellerio, notably in India it is an auspicious mythical animal venerated world over. The Maharaja was an important client of the jewelry houses of Place Vendôme and Rue de la Paix where he frequented to amass a truly unique collection of spectacular pieces of craftsmanship.
    <br/><br/>DUMMY TEXT To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began
    
    `,
  },
  {
    id: 10,
    // Img: "images/heritage/tank-watch.png",
    Img: [
      "images/heritage/tank-watch.png",
      "images/heritage/placeholder.png",
      "images/heritage/tank-watch.png",
      "images/heritage/placeholder.png",
      "images/heritage/tank-watch.png",
    ],
    Heading: "CARTIER TANK WATCH (CA, 1930)",
    Disc: `Donec feugiat in ipsum sagittis egestas. Ut congue ipsum nec nisi efficitur dictum. Integer varius egestas pharetra. Sed in elementum est, in rutrum lorem. Cras tempor euismod faucibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer tempus nunc at elementum laoreet. Nulla vitae semper lectus, id porta tortor. Nam lobortis vel dui in semper.
    
    <br/><br/>DUMMY TEXT To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began
    
    <br/><br/>our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began with Luxury brands that he began
    `,
  },
  {
    id: 11,
    // Img: "images/heritage/placeholder.png",
    Img: [
      "images/heritage/placeholder.png",
      "images/heritage/tank-watch.png",
      "images/heritage/placeholder.png",
      "images/heritage/tank-watch.png",
    ],
    Heading: " PLACEHOLDER",
    Disc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem. 
    <br/><br/>DUMMY TEXT To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began
    
    <br/><br/>our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began with Luxury brands that he began`,
  },
  {
    id: 12,
    // Img: "images/heritage/placeholder.png",
    Img: [
      "images/heritage/jjs-painting-1.webp",
      "images/heritage/jjs-painting-2.webp",
    ],
    Heading: " JJS PAINTING",
    Disc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem. 
    <br/><br/>DUMMY TEXT To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began
    
    <br/><br/>our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began with Luxury brands that he began`,
  },
  {
    id: 13,
    Img: ["images/heritage/cartier-sarpench-1.webp"],
    Heading: "CARTIER SARPENCH",
    Disc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem. 
    <br/><br/>DUMMY TEXT To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began`,
  },
  {
    id: 14,
    Img: [
      "images/heritage/monogram-cup-1.webp",
      "images/heritage/monogram-belt-1.webp",
    ],
    Heading: "MONOGRAM CUP/BELT",
    Disc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem. 
    <br/><br/>DUMMY TEXT To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began`,
  },
  {
    id: 15,
    Img: ["images/heritage/vase-1.webp"],
    Heading: "VASE",
    Disc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem. 
    <br/><br/>DUMMY TEXT To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began`,
  },
  {
    id: 16,
    Img: [
      "images/heritage/sita-devi-1.webp",
      "images/heritage/sita-devi-2.webp",
    ],
    Heading: "SITA DEVI",
    Disc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem. 
    <br/><br/>DUMMY TEXT To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began`,
  },
  {
    id: 17,
    Img: ["images/heritage/shoes-1.webp"],
    Heading: "SHOES",
    Disc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem. 
    <br/><br/>DUMMY TEXT To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began`,
  },
];

const ModelImg = [
  {
    id: 1,
    Heading: "CREST",
    modelImg: [
      "images/heritage/CREST_02.webp",
      "images/heritage/CREST-01.webp",
    ],
    longDisc: ` Embodying the motto and ethos of the Kapurthala state, this majestic Crest evolved along with Maharaja Jagajit Singh and the state. He directed modifications and adjustments of the graphic design and typography in line with his progressive reign and how he wanted to project / represent the dynamic dominion.</br> <br/>
        To commemorate the influence he's had on our perception of luxury we've recreated one of his favourite elements from the Crest. Notably, atop is a distinctive crown, that we have redesigned as our own logo. This emblem of our identity pledges our loyalty towards our longstanding relationships with Luxury brands that he began.
        `,
  },
  {
    id: 2,
    Heading: "CHATEAU",
    modelImg: [
      "images/heritage/chateau_1.webp",
      "images/heritage/chateau_2.webp",
    ],
    longDisc: `Following a winding road up the Xx hills of Mussoorie, tucked away behind majestic Deodar trees, is the summer abode of Maharaja of Kapurthala descendant of Tikka Singh founder of JMC.<br/><br/>

    This wondrous chateau, serves as a meaningful symbol for JMC: as the eclectic backdrop where lasting relationships were formed, the highest forms of experiential curation was extended and the most distinctive branded moments delighted all those who walked through its grounds and stayed within its chambers those included many of [the makers and designers of the items you see on the heritage page….eg. Jacques Cartier, etc.]<br/><br/>
    
    Going beyond just being a warm host, Jagatjit Singh went into the details of decoration, activity, agendas, so that each guests stay always retained his own personal touch, this indicates his style of curation, branding and extension of cultural storytelling that left an indelible mark. `,
  },
  {
    id: 3,
    Heading: `PATEK PHILIPPE POCKET WATCH (CA, 1877)`,
    modelImg: [
      "images/heritage/philipe-watch-1.webp",
      "images/heritage/philipe-watch-2.webp",
    ],
    longDisc: `An testament to the brands tagline "...one never owns a Patek Phillipe you merely look after it for the next generation". This cherished possession displays his personal association and respect from the maker: on the reverse is a hand-crafted engraving that merges the initials of Maharaja of Kapurthala with PP of Patek Philippe. A branding vision way ahead of its time.`,
  },
  {
    id: 4,
    Heading: "BACCARAT (CA, INSERT YEAR)",
    modelImg: [
      "images/heritage/baccarat-1.webp",
      "images/heritage/baccarat-2.webp",
    ],
    longDisc: `Hand carved crystal serveware and glasses <br/><br/>

    His connoisseurship for luxury and attention to detail of hosting and service, made him an highly regarded client by Baccarat, as evident in elaborate engraving of his initials on commissioned pieces. This added a personalized touch to the experience for his guests.
    `,
  },
  {
    id: 5,
    Heading: "LOUIS VUITTON TRUNKS (CA, 1837)",
    modelImg: [
      "images/heritage/LV-trunk-1.webp",
      "images/heritage/LV-trunk-2.webp",
      "images/heritage/LV-trunk-3.webp",
      "images/heritage/LV-trunk-4.webp",
    ],
    longDisc: `One of 50<br/> <br/>

    These were commissioned and tailored to his specific needs. These were as much a reflection of his discerning eye for luxury as it was for branding. While monogramming luxury goods was still a special service 100 years ago, Maharaja Jagajit Singh was one of the first recipients of the format that could personalize his possessions with his state colours and identity that we have also inherited. Discernable is our use of Royal Navy Blue and White.`,
  },
  {
    id: 6,
    Heading: "CARTIER TURBAN ORNAMENT (CA, 1926)",
    modelImg: ["images/heritage/cartier-turban-ornament-1.webp"],
    longDisc: `This features nineteen emeralds in varying sizes and shapes, the largest hexagonal emerald weighing 117.40 carats  and numerous pearls and white diamonds for accent.<br/> <br/> 

    A testament of his co-creation style that would mark his reign: the emeralds belonged to the vast treasury of the Maharajah, who commissioned Cartier to reset them in this exquisite modernized turban ornament. He sketched the famous pagoda style tiara by Cartier for his turban (that won accolades internationally.)<br/> <br/> 
    
    The design was pure Orientalism, a definite departure from the Art Deco style Cartier was known for during the 1920s, which only reinforces the avid interest he took in the design process of the objects he commissioned. `,
  },
  {
    id: 7,
    Heading: "CARTIER CORSAGE BROOCH (CA. 1905)",
    modelImg: ["images/heritage/cartier-corsage-1.webp"],
    longDisc: `One of many tokens of an unique romance, Maharaja Jagjit Singh, gifted this Brooch composed of a Belle Époque emerald and natural diamonds, as offering celestial emblem to his Maharani as a sign of his appreciation for her efforts to integrate into Indian culture. “Now you can have the moon…" His adoration of timelessly symbolic items gestures towards his understanding of branding, leaving unforgettable legendary memories in the hands of recipes.`,
  },
  {
    id: 8,
    Heading: "MOET CHANDON (CA, INSERT YEAR)",
    modelImg: [
      "images/heritage/moet-chandon-1.webp",
      "images/heritage/moet-chandon-2.webp",
    ],
    longDisc: `For over 100 years, was Moet & Chandon’s largest client. <br/><br/>

    Genuine mutual admiration marked the relationship [that flourished to becoming close friends]. Maharaja of Kapurthala as a connoisseur was curious to understand the making of champagne, and Claude Moet, impressed by his keenness, and attention to detail and service, opened their House to him. [Do we want to get into quantity / variation] Even today our association with Moet [spans…]`,
  },
  {
    id: 9,
    Heading: "MELLERIO DITS MELLER, (CA,1905)",
    modelImg: ["images/heritage/MELLERIO-DITS-MELLER.webp"],
    longDisc: `An Antique Diamond and Enamel Aigrette made with Rose-cut diamonds, blue, green, yellow and golden brown enamel, 18k gold (French marks), ins., 1905, signed Mellerio dit Meller, '9 Rue de la Paix, Paris'<br/><br/>

    The Maharaja of Kapurthala, found  this magnificent enamel and diamond peacock aigrette  unique piece at Mellerio, notably in India it is an auspicious mythical animal venerated world over. The Maharaja was an important client of the jewelry houses of Place Vendôme and Rue de la Paix where he frequented to amass a truly unique collection of spectacular pieces of craftsmanship.
    `,
  },
  {
    id: 10,
    Heading: "CARTIER TANK WATCH (CA, 1930)",
    modelImg: [
      "images/heritage/tank-watch.png",
      "images/heritage/placeholder.png",
      "images/heritage/tank-watch.png",
      "images/heritage/placeholder.png",
      "images/heritage/tank-watch.png",
    ],
    longDisc: `Donec feugiat in ipsum sagittis egestas. Ut congue ipsum nec nisi efficitur dictum. Integer varius egestas pharetra. Sed in elementum est, in rutrum lorem. Cras tempor euismod faucibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer tempus nunc at elementum laoreet. Nulla vitae semper lectus, id porta tortor. Nam lobortis vel dui in semper.`,
  },
  {
    id: 11,
    Heading: "PLACEHOLDER",
    modelImg: [
      "images/heritage/placeholder.png",
      "images/heritage/tank-watch.png",
      "images/heritage/placeholder.png",
      "images/heritage/tank-watch.png",
    ],
    longDisc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem.`,
  },
  {
    id: 12,
    Heading: "JJS PAINTING",
    modelImg: [
      "images/heritage/jjs-painting-1.webp",
      "images/heritage/jjs-painting-2.webp",
    ],
    longDisc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem.`,
  },
  {
    id: 13,
    Heading: "CARTIER SARPENCH",
    modelImg: ["images/heritage/cartier-sarpench-1.webp"],
    longDisc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem.`,
  },
  {
    id: 14,
    Heading: "CARTIER SARPENCH",
    modelImg: [
      "images/heritage/monogram-cup-1.webp",
      "images/heritage/monogram-belt-1.webp",
    ],
    longDisc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem.`,
  },
  {
    id: 15,
    Heading: "vase",
    modelImg: ["images/heritage/vase-1.webp"],
    longDisc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem.`,
  },
  {
    id: 16,
    Heading: "SITA DEVI",
    modelImg: ["images/heritage/SITA-DEVI-1.webp"],
    longDisc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Curabitur eget ex nulla. Vestibulum eget lorem viverra
    orci aliquet fermentum molestie et turpis. Aenean
    malesuada imperdiet enim, eu tristique arcu semper eu.
    Praesent laoreet bibendum nunc, nec scelerisque enim
    varius tincidunt. Proin ornare accumsan ornare. Nunc ut
    sagittis est, ac commodo sem. Vestibulum ante ipsum primis
    in faucibus orci luctus et ultrices posuere cubilia curae;
    Nulla eu consectetur sem.`,
  },
  {
    id: 17,
    Heading: "SHOES",
    modelImg: ["images/heritage/shoes-1.webp"],
    longDisc: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Curabitur eget ex nulla. Vestibulum eget lorem viverra
orci aliquet fermentum molestie et turpis. Aenean
malesuada imperdiet enim, eu tristique arcu semper eu.
Praesent laoreet bibendum nunc, nec scelerisque enim
varius tincidunt. Proin ornare accumsan ornare. Nunc ut
sagittis est, ac commodo sem. Vestibulum ante ipsum primis
in faucibus orci luctus et ultrices posuere cubilia curae;
Nulla eu consectetur sem.`,
  },
];

const HeritageScreen = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleImageClick = (index) => {
    setSelectedIndex(index);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      {/* breadcrumbs  */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6 text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#11134E"
          href="/heritage"
          className="text-[#11134E] fs-14"
        >
          HERITAGE
        </Link>
      </Breadcrumbs>

      <div className="flex items-center justify-center">
        <div className="w-[80%] pt-14  @sm:pt-8">
          {/* */}
          {/* section 1 */}
          <section className=" mb-10 @sm:mb-8 ">
            {/* desktop heading  */}
            <div className="flex items-center justify-center">
              <div className="w-[80%]"></div>
            </div>
            <div className="flex items-center justify-center mb-10 @sm:hidden ">
              <div className="">
                <img
                  className="@lg:w-[1.4vw]"
                  src="images/about/spear.svg"
                  alt="left border"
                />
              </div>
              <div className="border-1 border-[#DBDBDB] w-[36vw] ml-1"></div>
              <div className="fs-40 text-[#0b0b2f] mx-4 tracking-widest">
                HERITAGE
              </div>
              <div className="border-1 border-[#DBDBDB] w-[36vw] mr-1"></div>
              <div className="">
                <img
                  className="@lg:w-[1.4vw]  rotate-180"
                  src="images/about/spear.svg"
                  alt="left "
                />
              </div>
            </div>
            {/* mobile heading  */}
            <div className="border-b-1 border-[#DBDBDB] @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden @sm:mb-4 ">
              <div className="pb-1 tracking-widest text-center fs-40 fw-400">
                HERITAGE
              </div>
            </div>
            {/* description  */}
            <div className="flex items-center justify-center">
              <div className="@lg:w-[90%] @xl:w-[85%] @2xl:[90%] w-[80%] @sm:w-full @sm:pt-4">
                <p className="fs-16 text-center fw-400 text-[#545454] @sm:text-left">
                  While our independent and still family owned advisory was
                  established just 40 years ago, our deep bond and intimate
                  affiliation [relation / connection] with the pioneers of
                  Luxury, extends beyond a Century
                </p>
                <p className="fs-16 fw-400 text-center text-[#545454] my-4 @sm:text-left">
                  Our founder and Chairman, Tikka Singhs great grandfather,
                  Maharaja of Kapurthala Jagatjit Singh ( 1872 – 1949) initiated
                  this devotion towards handcrafted Luxury excellence.{" "}
                </p>
                <p className="fs-16 fw-400 text-center text-[#545454] mb-4 @sm:text-left">
                  The Maharajas of India left an enduring impression on the
                  history of design and luxury, stories of which we continue to
                  discover across hemispheres. It is this appreciation of savoir
                  faire and storytelling that has been passed down generations
                  and stay alive through our consultancy and work.
                </p>
                <p className="fs-16 fw-400 text-center text-[#545454] mb-4 @sm:text-left">
                  {" "}
                  Winston Churchill said of the Maharaja of Kapurthala ‘the
                  impress of his charming personality and cultivated taste was
                  apparent'. The Maharaja of Kapurthala is one of the most
                  global, progressive and aesthetically curious of the Indian
                  Maharajas.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <>
        <div className="mt-10 @sm:mt-0">
          {BoxData.map((items, index) => {
            const isAlternate = index % 2 !== 0;
            const characterLimit = 650;
            const truncatedDesc =
              items.Disc.length > characterLimit
                ? items.Disc.substring(0, characterLimit) + "..."
                : items.Disc;

            return (
              <div
                key={index}
                className={`items-center justify-center flex ${
                  isAlternate ? "flex-row-reverse" : "flex-row"
                }`}
              >
                <div className="w-[80%] @sm:w-[85%] bg-[#F4F4F4] my-10 @sm:my-5 rounded-lg ">
                  <div className="flex @sm:flex-col   w-full">
                    <div
                      className={`w-[35%] @sm:w-full @sm:p-0 h-full  ${
                        isAlternate ? "order-2" : ""
                      }`}
                    >
                      <Swiper
                        modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        onSlideChange={() => console.log("slide change")}
                        // autoplay={{ delay: 4000 }}
                      >
                        {items.Img.map((image, imgIndex) => (
                          <SwiperSlide key={imgIndex} className="">
                            <img
                              src={image}
                              alt={`Image ${index}-${imgIndex}`}
                              className="object-cover w-full bg-white aspect-square "
                              onClick={() => handleImageClick(index)}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>

                    <div
                      className={`px-6 pt-4 @md:p-5 @sm:px-6  @sm:py-6 w-[65%] @sm:w-full h-full ${
                        isAlternate ? "order-1 @sm:order-2" : ""
                      }`}
                    >
                      <p
                        className="fs-32 fw-400 text-[#0B0B2F] tracking-widest pb-1 @sm:text-center"
                        dangerouslySetInnerHTML={{ __html: items.Heading }}
                      >
                        {/* {items.Heading} */}
                      </p>
                      <div className="w-full border-b-2 border-[#BCBCBC] py-1 pb-5 @sm:pb-2" />
                      <p
                        className="fs-18 fw-400 text-[#545454] pt-4 @sm:pt-3 "
                        dangerouslySetInnerHTML={{ __html: items.Disc }}
                      >
                        {/* {showFullDesc[index] ? items.Disc : truncatedDesc} */}

                        {/* {items.Disc.length > characterLimit && (
                          <button
                            className="text-[#0B0B2F] fs-18 font-bold cursor-pointer ml-5 @sm:ml-1"
                            onClick={() => {
                              const newShowFullDesc = [...showFullDesc];
                              newShowFullDesc[index] = !newShowFullDesc[index];
                              setShowFullDesc(newShowFullDesc);
                            }}
                          >
                            {showFullDesc[index] ? "Read Less" : "Read More"}
                          </button>
                        )} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Modal
          open={openModal}
          onClose={closeModal}
          className="@sm:hidden @md:hidden @lgtab:hidden"
        >
          <div
            key={selectedIndex}
            className="bg-[#FBFBFB] w-full h-full  border-none flex flex-col p-6 "
          >
            <div>
              <div className="h-[60vh]   w-full ">
                <Swiper
                  className="w-full h-full "
                  spaceBetween={30}
                  slidesPerView={1}
                  navigation
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  autoplay={{ delay: 2000 }}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  initialSlide={0}
                >
                  {ModelImg[selectedIndex].modelImg.map((image, imgIndex) => (
                    <>
                      <SwiperSlide className="w-full" key={imgIndex}>
                        <img
                          src={image}
                          alt={`Model Image ${selectedIndex}-${imgIndex}`}
                          className=" mx-auto aspect-[4/1.5]   object-cover w-full rounded-[7px] bg-white "
                        />
                      </SwiperSlide>
                    </>
                  ))}
                </Swiper>

                <div
                  className=" absolute right-12 top-[4%] z-20"
                  onClick={closeModal}
                >
                  <img
                    alt="cross"
                    src="images/about/cross.png"
                    className="w-10 h-10 cursor-pointer"
                  />
                </div>
              </div>

              {/* description box  */}
              <div className="bg-[#fff] rounded-[7px] mx-14  -translate-y-10 p-5 z-20 absolute">
                <p
                  className="fs-32 fw-400 text-[#0B0B2F] tracking-widest @sm:text-center border-b-2 border-[#BCBCBC] pb-2 mb-5"
                  dangerouslySetInnerHTML={{
                    __html: ModelImg[selectedIndex].Heading,
                  }}
                ></p>

                <p
                  className="fs-18 fw-400 text-[#545454] pt-4 @sm:pt-3"
                  dangerouslySetInnerHTML={{
                    __html: ModelImg[selectedIndex].longDisc,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </Modal>
      </>
      {/*section 3 Modal */}
      {/* <Modal
        open={openModal}
        onClose={closeModal}
        className="@sm:hidden @md:hidden @lgtab:hidden"
      >
        <div className="bg-[#FBFBFB] w-full h-full border-none flex flex-col p-6 ">
          <div className="">
            <img
              alt="Selected Image"
              src="images/heritage/placeholder.png"
              className="h-[27%] @2xl:h-[35%] w-full rounded-[7px]"
              style={{ objectFit: "cover" }}
            />
            <div className=" absolute right-12 top-[4%]" onClick={closeModal}>
              <img
                src="images/about/cross.png"
                className="w-10 h-10 cursor-pointer"
              />
            </div>
            <div className="absolute top-[35%]">
              <div className="bg-[#fff] p-2.5 rounded-r-[7px]">
                <img
                  src="images/common/back-white.png"
                  className="w-5 h-5 cursor-pointer"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
            <div className="absolute top-[35%] right-6">
              <div className="bg-[#fff] p-2.5 rounded-l-[7px]">
                <img
                  src="images/common/foward-white.png"
                  className="w-5 h-5 cursor-pointer"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>

            <div className="bg-[#fff] rounded-[7px] mx-14 -translate-y-16 @2xl:p-14 p-6">
              <div className="fs-32 fw-400 text-[#0B0B2F] tracking-widest pb-1 @sm:text-center">
                PLACEHOLDER
              </div>
              <p className="fs-18 fw-400 text-[#545454] pt-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur eget ex nulla. Vestibulum eget lorem viverra orci
                aliquet fermentum molestie et turpis. Aenean malesuada imperdiet
                enim, eu tristique arcu semper eu. Praesent laoreet bibendum
                nunc, nec scelerisque enim varius tincidunt. Proin ornare
                accumsan ornare. Nunc ut sagittis est, ac commodo sem.
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                posuere cubilia curae; Nulla eu consectetur sem.
              </p>
            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default HeritageScreen;
