import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

const navlinks = [
  {
    title: "ABOUT",
    link: "/about-us",
  },
  {
    title: "SERVICES",
    link: "/service",
  },
  {
    title: "CLIENTS",
    link: "/Client",
  },
  {
    title: "HERITAGE",
    link: "/heritage",
  },
  {
    title: "CONTACT",
    link: "/contact",
  },
  {
    title: "CAREERS",
    link: "/career",
  },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef(null);

  const handleClick = () => {
    setIsOpen(true);
  };

  const headerClose = () => {
    setIsOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      // Click occurred outside the header, so close it
      setIsOpen(false);
    }
  };

  // Attach event listener when the header is open
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    // Clean up the event listener when the header is closed
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    // <nav
    //   className=" text-white flex justify-between items-center px-11 @sm:px-5 py-6"
    //   style={{ background: "radial-gradient(circle, #11115a, #0d0d3a)" }}
    // >
    //   <div className="w-[30px] @sm:w-[20px]"></div>
    //   <Link to="/">
    //     <img className="@sm:w-[14vw]" src="images/header/logo.svg" alt="logo" />
    //   </Link>
    //   <div ref={headerRef}>
    //     <div className="cursor-pointer  ">
    //       {isOpen ? (
    //         <img
    //           className="w-[30px] h-[30px] @sm:w-[20px] cursor-pointer"
    //           src="images/header/cross.svg"
    //           alt="cancel"
    //           onClick={headerClose}
    //         />
    //       ) : (
    //         <img
    //           className="w-[30px] h-[30px] @sm:w-[20px]"
    //           src="images/header/hamburger.svg"
    //           alt="menu"
    //           onClick={handleClick}
    //         />
    //       )}
    //     </div>
    //   </div>

    //   {isOpen && (
    //     <div
    //       className=" fixed z-10  top-0 h-[100vh] right-0 w-full pb-7"
    //       style={{ background: "radial-gradient(circle, #11115a, #0d0d3a)" }}
    //     >
    //       <div
    //         className=" text-white flex justify-between items-center px-11 @sm:px-5 py-6"
    //         style={{ background: "radial-gradient(circle, #11115a, #0d0d3a)" }}
    //       >
    //         <div className="w-[30px] @sm:w-[20px]"></div>
    //         <Link to="/">
    //           <img
    //             className="@sm:w-[18vw]"
    //             src="images/header/logo.svg"
    //             alt="logo"
    //           />
    //         </Link>
    //         <img
    //           className="w-[30px] h-[30px] @sm:w-[20px] cursor-pointer"
    //           src="images/header/cross.svg"
    //           alt="cancel"
    //           onClick={headerClose}
    //         />
    //       </div>
    //       <div className="flex-col text-center justify-evenly flex  @lg:w-2/4 mx-auto pt-5 text-white">
    //         {navlinks.map((item, index) => {
    //           return (
    //             <Link
    //               key={index}
    //               to={item.link}
    //               onClick={headerClose}
    //               className="py-8"
    //             >
    //               <p className="text-white text-lg">{item.title}</p>
    //             </Link>
    //           );
    //         })}
    //       </div>
    //     </div>
    //   )}
    // </nav>
    <nav
      className={`text-white flex justify-between items-center px-11 @sm:px-5 py-6 w-full object-left-top ${
        isOpen ? "" : ""
      }`}
      style={{
        background: isOpen
          ? "linear-gradient(to right, #0d0d3a, #11115a ,#0d0d3a)"
          : "linear-gradient(to right, #0d0d3a, #11115a ,#0d0d3a)",
      }}
    >
      <div className="w-[30px] @sm:w-[20px]"></div>
      <Link to="/">
        <img
          className="@sm:w-[16vw] "
          src="images/header/logo.svg "
          alt="logo"
        />
      </Link>
      <div ref={headerRef}>
        <div className="cursor-pointer">
          {isOpen ? (
            <img
              className="w-[30px] h-[30px] @sm:w-[20px]  cursor-pointer "
              src="images/header/cross.svg"
              alt="cancel"
              onClick={headerClose}
            />
          ) : (
            <img
              className="w-[30px] h-[30px] @sm:w-[20px] "
              src="images/header/hamburger.svg"
              alt="menu"
              onClick={handleClick}
            />
          )}
        </div>
      </div>

      {/* {isOpen && ( */}
      <>
        <div
          className={`fixed bottom-0 right-0 h-full w-full transition-transform transform ease-in-out -z-10 ${
            isOpen
              ? "translate-y-0 duration-[500ms]"
              : "-translate-y-full duration-[500ms]"
          }`}
          // className=""
          style={{
            background: "linear-gradient(to right, #0d0d3a, #11115a ,#0d0d3a)",
          }}
        >
          <div
            className="text-white flex justify-between items-center px-11 @sm:px-5 py-6 "
            // style={{ background: "radial-gradient(circle, #11115a, #0d0d3a)" }}
          >
            <div className="w-[30px] @sm:w-[20px] "></div>
            <Link to="/">
              <img
                className="@sm:w-[16vw]"
                src="images/header/logo.svg"
                alt="logo"
              />
            </Link>
            <img
              className="w-[30px] h-[30px] @sm:w-[20px] cursor-pointer "
              src="images/header/cross.svg"
              alt="cancel"
              onClick={headerClose}
            />
          </div>
          <div
            className={`flex-col text-center justify-evenly flex @lg:w-2/4 mx-auto pt-5 text-white ${
              isOpen ? "" : ""
            }`}
          >
            {navlinks.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.link}
                  onClick={headerClose}
                  className="py-8"
                >
                  <p className="text-white text-lg">{item.title}</p>
                </Link>
              );
            })}
          </div>
        </div>
      </>
      {/* )} */}
    </nav>
  );
};

export default Header;
