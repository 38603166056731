import { Breadcrumbs } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ServicesDetails from "../../components/services-details";
import ServicesDetailsBox from "../../components/services-details-box";
import Pagination from "../../components/pagination";
import Infinity from "../.././assets/Animation/Infinity-Final(1).json";
import InfinityMob from "../.././assets/Animation/Infinity-final(1)-Mob.json";

import Lottie from "lottie-react";

const BoxData = [
  {
    id: 1,
    Img: "/images/services/performances/2.png",
    Heading: "OFFLINE/RETAIL PERFORMANCE",
    Disc: "Being the dominant channel for luxury sales, the focus on Retail Performance is crucial to influence business decisions, identify potential risks, improve forecasting, establish benchmarking, drive employee performance and top line growth - among many other benefits. We not only analyse performance, but also provide targeted strategies to increase odds of success against a rapidly evolving client and hyper-competitive industry.",
  },
  {
    id: 2,
    Img: "/images/services/performances/1.png",
    Heading: "ONLINE PERFORMANCE",
    Disc: `Sales, profits, traffic are all obvious to track, but they’re just a few of many metrics to consider when trying to drive performance for today’s fastest growing sales channel. The evolution of performance management has pushed the need for a more proactive monitoring mindset, especially when preparing for high-traffic events. The list of metrics combined with the details to deliver application seamlessness can be overwhelming for any business. Our team of experts is dedicated to help you drive the highest potential from this channel and it’s clients.`,
  },
  {
    id: 3,
    Img: "/images/services/performances/3.png",
    Heading: "OMNI CHANNEL PERFORMANCE",
    Disc: "Omni channel is an approach to sales and marketing that provides customers with a seamless and unified brand experience, regardless of which channel they use. The lines between what we do online and in real life have begun to blur, which is why brands need to be approaching their business in a holistic way. Giving clients a chance to shop their preferred channel increases the chance to convert and drive loyalty as it also maximises the client experience, expands reach, simplifies the purchase process, consolidates inventory, improves churn rates and LTV. Our team of experts works across channels and departments to develop the tools and performance of this channel, getting you closer to the client and keeping you ahead of your closest competitors.",
  },
];

const PerformanceManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* breadcrumbs  */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6 text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#707070"
          to="/service"
          className="text-[#707070] fs-14"
        >
          SERVICES
        </Link>
        <Link
          underline="hover"
          color="#11134E"
          href="/AnalyticsService"
          className="text-[#11134E] fs-14"
        >
          PERFORMANCE MANAGEMENT
        </Link>
      </Breadcrumbs>

      <>
        <ServicesDetails
          HeadingWidth={"w-[20%]"}
          HeadingTxtWidth={"@lg:w-[66%] @xl:w-[66%] @2xl:w-[66%]"}
          MobHeading={"PERFORMANCE MANAGEMENT"}
          Heading={"PERFORMANCE MANAGEMENT"}
          SubHeading={"Your Performance, Our Expertise"}
          Description={`JMC’s Performance Management services are dedicated to optimising business intelligence and driving multi-channel sales performance for your brand and business.
          <br/><br/>

          Our approach leverages data and insights to identify growth opportunities and deliver targeted actions to improve existing trends and key performance indicators (KPI’s), which in turn will increase revenue. To act with foresight and maximise business potential, it is important for brands to have a holistic approach of their performance and operate in all intersections of retail, online and omni channel.
<br/><br/>
          
With Online sales growing rapidly and digital transformations being essential for the survival of any brand today, the luxury industry’s revenues remain dominated by retail sales. Omni-Channel experiences have also become a necessity for brands to not miss new or additional business. Brands must evolve with the client.
<br/><br/>
          
Our expertise across channels helps you optimise and even integrate these channels seamlessly, to drive the highest conversion (and other supporting metrics) from your visitors. Our services can be utilised individually or as one key function, as we excel in fulfilling brand potential and delivering enhanced business performance and profitability.
`}
        />
      </>

      <div className="">
        {window.innerWidth >= 768 ? (
          <Lottie animationData={Infinity} />
        ) : (
          <Lottie animationData={InfinityMob} />
        )}
      </div>

      <div className="p-5">
        <ServicesDetailsBox BoxData={BoxData} />
      </div>
      <div className="pt-10 pb-20">
        <Pagination
          prevLink={"/ClientDevelopment"}
          prevPage={"BACK TO CLIENT DEVELOPMENT"}
          nextPage={"NEW MARKET ENTRY"}
          nextLink={"/NewMarketEntry"}
          mobHeading={"PERFORMANCE MANAGEMENT"}
        />
      </div>
    </div>
  );
};

export default PerformanceManagement;
