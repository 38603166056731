import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const HomeScreen = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // When the component mounts, trigger the fade-in effect
    setIsVisible(true);
  }, []);
  return (
    <>
      {window.innerWidth >= 768 ? (
        <>
          {/* <div className="h-screen  bg-[#fff] "> */}
          <div
            className={`transition-all duration-[500ms] ease-in-out ${
              isVisible
                ? "opacity-100 overflow-hidden h-screen"
                : "opacity-0 scale-50"
            }`}
          >
            {/* new svgg */}

            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 1920 1080"
              className="h-screen w-full fixed left-0 right-0 scale-x-[1.243] @lg:scale-x-[1.21] @md:scale-y-[1.15] @lg:scale-y-[1.12] scale-y-[1.12]"
            >
              <defs>
                <style>{`.cls-1{fill:transparent;cursor:pointer;}
                .cls-1:hover{fill:red;}
                .cls-2,.cls-3,.cls-4,
                .cls-5{isolation:isolate;}
                .cls-3,.cls-4,.cls-5{font-size:16px;font-family:Lusitana-Bold,Lusitana;font-weight:700;}
                .cls-3{fill:#1c184c;
                cursor: pointer}
                
                 .cls-3,.cls-4,.cls-7{letter-spacing:0.12em;}
                 .cls-4,.cls-5{fill:#1c184d;}.cls-5{letter-spacing:0.12em;}
                 .cls-6{letter-spacing:0.06em;}
                 
                 #ABOUT_US:hover > .cls-1   {
                  fill: #101052;
                  cursor: pointer ;
                }
    
                #SERVICES:hover > .cls-1   {
                  fill: #101052;
                  cursor: pointer ;
                }
                #CLIENTS:hover > .cls-1   {
                  fill: #101052;
                  cursor: pointer ;
                }
                #HERITAGES:hover > .cls-1   {
                  fill: #101052;
                  cursor: pointer ;
                }

                #ABOUT_US:hover text.cls-3 {
                  fill: white;
                  cursor: pointer ;
                }
                #SERVICES:hover text.cls-3 {
                  fill: white;
                  cursor: pointer ;
                }
                #CLIENTS:hover text.cls-4 {
                  fill: white;
                  cursor: pointer ;
                }
                #HERITAGES:hover text.cls-5 {
                  fill: white;
                  cursor: pointer ;
                }
                 
                 `}</style>
              </defs>

              <image
                x="0"
                y="0"
                width="100%"
                height="100%"
                xlinkHref="/images/home/jmc_png.png"
                className="object-cover absolute right-0 border-2 border-success "
              />

              <g id="Layer_1-2" data-name="Layer 1">
                <Link to={"/about-us"}>
                  <g id="ABOUT_US" data-name="ABOUT US">
                    <path
                      class="cls-1"
                      d="M694.66,632.48H819.6V564.4s-5.23-.94-2.55-8.09l-8.43-5.62s1.66,2.17-1.28,5.75-3.57-8-2.17-8.55-7-10.86-7-10.86-.25,5.24-2.29,6.9c0,0-4.22-.51-3.45-10.21H722.2s.89,6.25-1.66,10.59c0,0-3.32-1-3.32-6.38,0,0-8.05,8-7.28,11.36s-.77,12.51-4.85,2.3c0,0-7.4,3.7-6.89,4.47s2,6.25-2.81,9.19Z"
                    />
                    <path
                      class="cls-1"
                      d="M695.51,546v9.45l5.11-4.73S697.56,550.31,695.51,546Z"
                    />
                    <path
                      class="cls-1"
                      d="M695.64,543.89s.39-4.73,5.11-5.37c0,0-5.24-1.66-5.24-3.57Z"
                    />
                    <path
                      class="cls-1"
                      d="M716.37,534.35H699S709.6,540.35,716.37,534.35Z"
                    />
                    <path
                      class="cls-1"
                      d="M712.75,538.82,707.64,546s1.22-5.86-3.44-7.15Z"
                    />
                    <path
                      class="cls-1"
                      d="M802.75,539.57a13.8,13.8,0,0,1,4.34,5.76s-.64-5,2.68-6.51Z"
                    />
                    <path
                      class="cls-1"
                      d="M819.6,535.05v7.35a8.91,8.91,0,0,0-6.51-4.85S818.07,536.77,819.6,535.05Z"
                    />
                    <path
                      class="cls-1"
                      d="M818.2,533.72H797S804.41,538.06,818.2,533.72Z"
                    />
                    <path
                      class="cls-1"
                      d="M819.6,546.61s-3.19,5.11-6.51,3.57c0,0,5.23,2.43,5.74,4.47S819.6,546.61,819.6,546.61Z"
                    />
                    <g id="About_Text" data-name="About Text">
                      <g class="cls-2">
                        <text
                          class="cls-3"
                          transform="translate(722.62 599.22)"
                        >
                          ABOUT
                        </text>
                      </g>
                    </g>
                  </g>
                </Link>
                <Link to={"/service"}>
                  <g id="SERVICES">
                    <path
                      class="cls-1"
                      d="M829.87,632.86H954.81V564.78s-5.24-.94-2.55-8.09l-8.43-5.61s1.66,2.17-1.28,5.74-3.57-8-2.17-8.55-7-10.85-7-10.85-.25,5.23-2.3,6.89c0,0-4.21-.51-3.44-10.21H857.4s.9,6.25-1.66,10.59c0,0-3.31-1-3.31-6.38,0,0-8,8-7.28,11.36s-.77,12.51-4.85,2.3c0,0-7.41,3.7-6.9,4.47s2.05,6.25-2.8,9.19Z"
                    />
                    <path
                      class="cls-1"
                      d="M830.72,546.35v9.45l5.11-4.72S832.77,550.69,830.72,546.35Z"
                    />
                    <path
                      class="cls-1"
                      d="M830.85,544.27s.38-4.73,5.11-5.36c0,0-5.24-1.66-5.24-3.58Z"
                    />
                    <path
                      class="cls-1"
                      d="M851.57,534.74H834.21S844.81,540.74,851.57,534.74Z"
                    />
                    <path
                      class="cls-1"
                      d="M848,539.2l-5.11,7.15s1.22-5.86-3.45-7.15Z"
                    />
                    <path
                      class="cls-1"
                      d="M938,540a13.8,13.8,0,0,1,4.34,5.76s-.64-5,2.68-6.51Z"
                    />
                    <path
                      class="cls-1"
                      d="M954.81,535.43v7.35a8.91,8.91,0,0,0-6.51-4.85S953.28,537.15,954.81,535.43Z"
                    />
                    <path
                      class="cls-1"
                      d="M953.4,534.1H932.21S939.62,538.44,953.4,534.1Z"
                    />
                    <path
                      class="cls-1"
                      d="M954.81,547s-3.19,5.11-6.51,3.58c0,0,5.23,2.42,5.74,4.46S954.81,547,954.81,547Z"
                    />
                    <g
                      id="Services_text"
                      data-name="Services text"
                      class="cls-2"
                    >
                      <text class="cls-3" transform="translate(844.09 599.6)">
                        SERVICES
                      </text>
                    </g>
                  </g>
                </Link>
                <Link to={"/Client"}>
                  <g id="CLIENTS">
                    <path
                      class="cls-1"
                      d="M965.64,632.48h124.93V564.4s-5.23-.94-2.55-8.09l-8.43-5.62s1.66,2.17-1.27,5.75-3.58-8-2.17-8.55-7-10.86-7-10.86-.25,5.24-2.29,6.9c0,0-4.22-.51-3.45-10.21H993.17s.89,6.25-1.66,10.59c0,0-3.32-1-3.32-6.38,0,0-8,8-7.28,11.36s-.76,12.51-4.85,2.3c0,0-7.4,3.7-6.89,4.47s2,6.25-2.81,9.19Z"
                    />
                    <path
                      class="cls-1"
                      d="M966.49,546v9.45l5.1-4.73S968.53,550.31,966.49,546Z"
                    />
                    <path
                      class="cls-1"
                      d="M966.61,543.89s.39-4.73,5.11-5.37c0,0-5.23-1.66-5.23-3.57Z"
                    />
                    <path
                      class="cls-1"
                      d="M987.34,534.35H970S980.57,540.35,987.34,534.35Z"
                    />
                    <path
                      class="cls-1"
                      d="M983.72,538.82,978.61,546s1.22-5.86-3.44-7.15Z"
                    />
                    <path
                      class="cls-1"
                      d="M1073.72,539.57a13.71,13.71,0,0,1,4.34,5.76s-.64-5,2.68-6.51Z"
                    />
                    <path
                      class="cls-1"
                      d="M1090.57,535.05v7.35a8.91,8.91,0,0,0-6.51-4.85S1089,536.77,1090.57,535.05Z"
                    />
                    <path
                      class="cls-1"
                      d="M1089.17,533.72H1068S1075.38,538.06,1089.17,533.72Z"
                    />
                    <path
                      class="cls-1"
                      d="M1090.57,546.61s-3.19,5.11-6.51,3.57c0,0,5.24,2.43,5.75,4.47S1090.57,546.61,1090.57,546.61Z"
                    />
                    <g id="Client_Text" data-name="Client Text" class="cls-2">
                      <text class="cls-4" transform="translate(985.22 599.22)">
                        CLIENTS
                      </text>
                    </g>
                  </g>
                </Link>
                <Link to={"/heritage"}>
                  <g id="HERITAGES">
                    <path
                      class="cls-1"
                      d="M1100.13,633.1h124.94V565s-5.24-.93-2.55-8.08l-8.43-5.62s1.66,2.17-1.28,5.75-3.57-8-2.17-8.56-7-10.85-7-10.85-.25,5.24-2.3,6.9c0,0-4.21-.52-3.44-10.22h-70.22s.9,6.26-1.66,10.6c0,0-3.31-1-3.31-6.38,0,0-8,8-7.28,11.36s-.77,12.51-4.85,2.3c0,0-7.41,3.7-6.9,4.46s2,6.26-2.8,9.19Z"
                    />
                    <path
                      class="cls-1"
                      d="M1101,546.59V556l5.11-4.72S1103,550.93,1101,546.59Z"
                    />
                    <path
                      class="cls-1"
                      d="M1101.11,544.5s.38-4.72,5.11-5.36c0,0-5.24-1.66-5.24-3.57Z"
                    />
                    <path
                      class="cls-1"
                      d="M1121.83,535h-17.36S1115.07,541,1121.83,535Z"
                    />
                    <path
                      class="cls-1"
                      d="M1118.22,539.44l-5.11,7.15s1.22-5.86-3.45-7.15Z"
                    />
                    <path
                      class="cls-1"
                      d="M1208.22,540.18a13.89,13.89,0,0,1,4.34,5.77s-.64-5,2.68-6.51Z"
                    />
                    <path
                      class="cls-1"
                      d="M1225.07,535.67V543a8.91,8.91,0,0,0-6.51-4.85S1223.54,537.38,1225.07,535.67Z"
                    />
                    <path
                      class="cls-1"
                      d="M1223.66,534.33h-21.19S1209.88,538.67,1223.66,534.33Z"
                    />
                    <path
                      class="cls-1"
                      d="M1225.07,547.23s-3.19,5.1-6.51,3.57c0,0,5.23,2.43,5.74,4.47S1225.07,547.23,1225.07,547.23Z"
                    />
                    <g
                      id="Heritage_text"
                      data-name="Heritage text"
                      class="cls-2"
                    >
                      <text class="cls-5" transform="translate(1111.43 599.84)">
                        HERI
                        <tspan class="cls-6" x="48.94" y="0">
                          T
                        </tspan>
                        <tspan class="cls-7" x="60.8" y="0">
                          AGE
                        </tspan>
                      </text>
                    </g>
                  </g>
                </Link>
              </g>
            </svg>
          </div>
        </>
      ) : (
        <>
          {/* New Mobile Svg */}
          <div
            // className="fixed w-full h-full "
            className={`transition-all duration-[500ms] ease-in-out ${
              isVisible
                ? "opacity-100 overflow-hidden h-screen"
                : "opacity-0 scale-50"
            }`}
          >
            <img
              className=" w-full h-[20vh] object-cover object-bottom"
              alt="top"
              src="/images/home/mob-chatue/top.png"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 259.2 445.92"
              className=""
            >
              <defs>
                <style>
                  {`.cls-1{fill:#101052;}.cls-2{isolation:isolate;}.cls-3,.cls-4{font-size:16px;fill:#fff;font-family:Lusitana-Bold, Lusitana;font-weight:700;}.cls-3,.cls-6{letter-spacing:0.12em;}.cls-4{letter-spacing:0.12em;}.cls-5{letter-spacing:0.06em;}`}
                </style>
                <image
                  id="image"
                  width="540"
                  height="513"
                  xlinkHref="/images/home/mob-chatue/middle.png"
                />
                <image
                  id="image-2"
                  width="542"
                  height="513"
                  xlinkHref="/images/home/mob-chatue/middle.png"
                />
              </defs>
              <g id="Layer_1_copy_3_Image" data-name="Layer 1 copy 3 Image">
                <use
                  id="Layer_1_Image-2"
                  data-name="Layer 1 Image"
                  transform="translate(129.6) scale(0.24)"
                  xlinkHref="#image"
                />
                <use
                  id="Layer_1_copy_2_Image-2"
                  data-name="Layer 1 copy 2 Image"
                  transform="scale(0.24)"
                  xlinkHref="#image-2"
                />
                <use
                  id="Layer_1_copy_Image-2"
                  data-name="Layer 1 copy Image"
                  transform="translate(129.6 122.88) scale(0.24)"
                  xlinkHref="#image"
                />
                <use
                  id="Layer_1_copy_3_Image-2"
                  data-name="Layer 1 copy 3 Image"
                  transform="translate(0 122.88) scale(0.24)"
                  xlinkHref="#image-2"
                />
              </g>

              <g id="Layer_2_Image" data-name="Layer 2 Image">
                <image
                  id="Layer_2_Image-2"
                  data-name="Layer 2 Image"
                  width="1080"
                  height="833"
                  transform="translate(0 246) scale(0.24)"
                  xlinkHref="/images/home/mob-chatue/bottom.png"
                />
              </g>
              <g id="Layer_6" data-name="Layer 6">
                <Link to={"/about-us"}>
                  <g id="ABOUT_GROUP">
                    <path
                      id="ABOUT_US"
                      class="cls-1"
                      d="M5,113.22,4.78,48.41s3.7-.48,3.06-8.45l6.92-4.14S15.64,40,17.25,40s2.41-2.47,1.29-7.25c.88-2.55,3.94-6.54,7.4-10.13-.08,2.32.4,5.42,2.65,6.14,0,0,2.73-.64,2.41-7.25L30.76,19h68s-1.61,8.37,2.58,9.8c1.77-.48,2.73-6.54,2.73-6.54s6.68,8.69,6.6,11-1.45,3.58,0,5.5S113.52,40,114,39a5.94,5.94,0,0,0,.65-3.27c1,.71,6.91,3.5,6.91,4.46s.73.4-.48,2.23.73,5.58,3.54,5.18c-.08,2.63,0,65.29,0,65.29ZM21.07,23.1s-1.09,1-6.27.6c.24.84,2,1.67,2.35,5.14a52.44,52.44,0,0,0,3.92-5.44Zm-10.5-.44s-3.7-.79-5.38-2.55c-.41,1.92-.81,6.7,0,6S7.76,22.82,10.57,22.66Zm6.56-1.35a16.9,16.9,0,0,0,7.92-2.23H9.21A16.9,16.9,0,0,0,17.13,21.31ZM5.27,33v5.26s4-3.35,3.94-3.11A23.84,23.84,0,0,1,5.27,33Zm7.4-6.54c1.52.08.88,2.15.8,2.79s1.77,1,1.77,1C15.32,24.9,11.7,25,11.7,25,11.3,27.21,12.67,26.49,12.67,26.49Zm-2.1,0V25s-3.43.27-3.33,4.79C8.48,29.84,8.6,29,8.9,28S9.19,26.44,10.57,26.49Zm-.8,3.75S7.7,30,7.65,31.11,10,33.58,12.26,33s2.66-3,2-2.79a2.81,2.81,0,0,1-1.52,0s-1,1.46-2.18,1.14S9.77,30.24,9.77,30.24ZM108.38,23a53.57,53.57,0,0,0,3.92,5.44c.36-3.47,2.11-4.31,2.35-5.14-5.19.36-6.27-.6-6.27-.6Zm15.88,2.69c.8.72.4-4.07,0-6-1.69,1.75-5.39,2.55-5.39,2.55C121.69,22.4,123.46,25,124.26,25.67Zm-4-7H104.39a15.14,15.14,0,0,0,15.85,0Zm0,16.11c-.08-.24,3.94,3.11,3.94,3.11V32.6A23.72,23.72,0,0,1,120.24,34.76Zm-2.5-10.21s-3.61-.08-3.53,5.26c0,0,1.84-.31,1.76-.95s-.72-2.71.81-2.79C116.78,26.07,118.15,26.78,117.74,24.55Zm2.8,3c.3,1,.42,1.84,1.67,1.76.09-4.53-3.34-4.79-3.34-4.79v1.52C120.26,26,120.24,26.55,120.54,27.58Zm-1.67,3.35c-1.21.32-2.17-1.14-2.17-1.14a2.89,2.89,0,0,1-1.53,0c-.64-.24-.24,2.24,2,2.79s4.67-.79,4.62-1.91-2.12-.88-2.12-.88S120.08,30.61,118.87,30.93Z"
                      transform="translate(0 -14.88)"
                    />
                    <g id="About_Text" data-name="About Text">
                      <g class="cls-2">
                        <text class="cls-3" transform="translate(30.21 55.18)">
                          ABOUT
                        </text>
                      </g>
                    </g>
                  </g>
                </Link>
                <Link to={"/service"}>
                  <g id="SERVICES_GROUP">
                    <path
                      id="SERVICES"
                      class="cls-1"
                      d="M134.7,113.15l-.24-64.81s3.7-.48,3.06-8.45l6.91-4.14s.89,4.22,2.5,4.22,2.41-2.47,1.28-7.25c.89-2.55,3.94-6.54,7.4-10.13-.08,2.31.41,5.42,2.66,6.14,0,0,2.73-.64,2.41-7.25l-.24-2.55h68s-1.61,8.37,2.57,9.8c1.77-.48,2.74-6.54,2.74-6.54s6.67,8.69,6.59,11-1.44,3.58,0,5.5,2.9,1.19,3.38.23a5.89,5.89,0,0,0,.65-3.26c1,.71,6.91,3.5,6.91,4.46s.73.4-.48,2.23.72,5.58,3.54,5.18c-.08,2.63,0,65.29,0,65.29ZM150.75,23s-1.09,1-6.28.6c.25.84,2,1.67,2.36,5.14a54.08,54.08,0,0,0,3.92-5.44Zm-10.5-.44s-3.7-.79-5.39-2.55c-.4,1.92-.8,6.7,0,6S137.44,22.75,140.25,22.59Zm6.56-1.35A16.9,16.9,0,0,0,154.73,19H138.88A16.91,16.91,0,0,0,146.81,21.24ZM134.94,33v5.26s4-3.35,3.94-3.11A24.25,24.25,0,0,1,134.94,33Zm7.4-6.54c1.53.08.89,2.15.81,2.79s1.77,1,1.77,1c.08-5.35-3.54-5.27-3.54-5.27C141,27.14,142.34,26.42,142.34,26.42Zm-2.09,0V24.9s-3.43.27-3.34,4.79c1.25.08,1.37-.72,1.67-1.76S138.87,26.37,140.25,26.42Zm-.8,3.75s-2.08-.24-2.13.87,2.37,2.47,4.62,1.92,2.65-3,2-2.79a2.85,2.85,0,0,1-1.53,0s-1,1.46-2.17,1.14S139.45,30.17,139.45,30.17Zm98.6-7.26A55.28,55.28,0,0,0,242,28.35c.37-3.47,2.11-4.31,2.36-5.14-5.19.36-6.28-.6-6.28-.6Zm15.89,2.69c.8.72.4-4.07,0-6-1.69,1.75-5.39,2.55-5.39,2.55C251.36,22.33,253.13,24.88,253.94,25.6Zm-4-7H234.07a15.14,15.14,0,0,0,15.85,0Zm0,16.11c-.08-.24,3.94,3.1,3.94,3.1V32.53A23.72,23.72,0,0,1,249.92,34.69Zm-2.5-10.21s-3.62-.08-3.54,5.26c0,0,1.85-.32,1.77-.95s-.72-2.71.81-2.79C246.46,26,247.82,26.71,247.42,24.48Zm2.8,3c.3,1,.42,1.83,1.67,1.76.09-4.53-3.34-4.79-3.34-4.79V26C249.93,26,249.92,26.48,250.22,27.51Zm-1.67,3.35c-1.21.32-2.17-1.14-2.17-1.14a2.89,2.89,0,0,1-1.53,0c-.64-.24-.24,2.24,2,2.79s4.66-.79,4.62-1.91-2.13-.88-2.13-.88S249.75,30.54,248.55,30.86Z"
                      transform="translate(0 -14.88)"
                    />
                    <g
                      id="Services_text"
                      data-name="Services text"
                      class="cls-2"
                    >
                      <text class="cls-3" transform="translate(149.89 61.63)">
                        SERVICES
                      </text>
                    </g>
                  </g>
                </Link>
                <Link to={"/Client"}>
                  <g id="CLIENTS_GROUP">
                    <path
                      id="CLIENTS"
                      class="cls-1"
                      d="M4.82,236.15l-.25-64.81s3.7-.48,3.06-8.45l6.92-4.14S15.43,163,17,163s2.41-2.47,1.29-7.25c.88-2.55,3.94-6.54,7.4-10.13-.08,2.31.4,5.42,2.65,6.14,0,0,2.74-.64,2.41-7.25l-.24-2.55h68s-1.6,8.37,2.58,9.8c1.77-.48,2.73-6.54,2.73-6.54s6.68,8.69,6.6,11-1.45,3.58,0,5.5,2.89,1.19,3.38.23a5.93,5.93,0,0,0,.64-3.26c1,.71,6.92,3.5,6.92,4.46s.72.4-.49,2.23.73,5.58,3.54,5.18c-.08,2.63,0,65.29,0,65.29Zm16-90.12s-1.08,1-6.27.6c.24.84,2,1.67,2.35,5.14a52.44,52.44,0,0,0,3.92-5.44Zm-10.49-.44S6.67,144.8,5,143c-.41,1.92-.81,6.7,0,6S7.55,145.75,10.37,145.59Zm6.55-1.35A16.9,16.9,0,0,0,24.84,142H9A16.9,16.9,0,0,0,16.92,144.24ZM5.06,156v5.26s4-3.35,3.94-3.11A23.84,23.84,0,0,1,5.06,156Zm7.4-6.54c1.52.08.88,2.15.8,2.79s1.77,1,1.77,1c.08-5.35-3.54-5.27-3.54-5.27C11.09,150.14,12.46,149.42,12.46,149.42Zm-2.09,0V147.9s-3.44.27-3.34,4.79c1.24.08,1.36-.72,1.67-1.76S9,149.37,10.37,149.42Zm-.81,3.75s-2.07-.24-2.12.87,2.36,2.47,4.61,1.92,2.66-3,2-2.79a2.84,2.84,0,0,1-1.53,0s-1,1.46-2.17,1.14S9.56,153.17,9.56,153.17Zm98.61-7.26a53.57,53.57,0,0,0,3.92,5.44c.36-3.47,2.11-4.31,2.35-5.14-5.19.36-6.27-.6-6.27-.6Zm15.88,2.69c.81.72.4-4.07,0-6-1.69,1.75-5.39,2.55-5.39,2.55C121.48,145.33,123.25,147.88,124.05,148.6Zm-4-7H104.19a15.12,15.12,0,0,0,15.84,0Zm0,16.11c-.08-.24,3.94,3.1,3.94,3.1v-5.26A23.32,23.32,0,0,1,120,157.69Zm-2.49-10.21s-3.62-.08-3.54,5.26c0,0,1.85-.32,1.77-1s-.73-2.71.8-2.79C116.57,149,117.94,149.71,117.54,147.48Zm2.79,3c.3,1,.42,1.83,1.67,1.76.09-4.53-3.34-4.79-3.34-4.79V149C120.05,149,120,149.48,120.33,150.51Zm-1.67,3.35c-1.2.32-2.17-1.14-2.17-1.14a2.88,2.88,0,0,1-1.53,0c-.64-.24-.24,2.24,2,2.79s4.67-.79,4.62-1.91-2.12-.88-2.12-.88S119.87,153.54,118.66,153.86Z"
                      transform="translate(0 -14.88)"
                    />
                    <g id="Client_Text" data-name="Client Text" class="cls-2">
                      <text class="cls-3" transform="translate(20.86 184.13)">
                        CLIENTS
                      </text>
                    </g>
                  </g>
                </Link>
                <Link to={"/heritage"}>
                  <g id="HERITAGE_GROUP">
                    <path
                      id="HERITAGES"
                      class="cls-1"
                      d="M134.7,236.15l-.24-64.81s3.7-.48,3.06-8.45l6.91-4.14s.89,4.22,2.5,4.22,2.41-2.47,1.28-7.25c.89-2.55,3.94-6.54,7.4-10.13-.08,2.31.41,5.42,2.66,6.14,0,0,2.73-.64,2.41-7.25l-.24-2.55h68s-1.61,8.37,2.57,9.8c1.77-.48,2.74-6.54,2.74-6.54s6.67,8.69,6.59,11-1.44,3.58,0,5.5,2.9,1.19,3.38.23a5.89,5.89,0,0,0,.65-3.26c1,.71,6.91,3.5,6.91,4.46s.73.4-.48,2.23.72,5.58,3.54,5.18c-.08,2.63,0,65.29,0,65.29ZM150.75,146s-1.09,1-6.28.6c.25.84,2,1.67,2.36,5.14a54.08,54.08,0,0,0,3.92-5.44Zm-10.5-.44s-3.7-.79-5.39-2.55c-.4,1.92-.8,6.7,0,6S137.44,145.75,140.25,145.59Zm6.56-1.35a16.9,16.9,0,0,0,7.92-2.23H138.88A16.91,16.91,0,0,0,146.81,144.24ZM134.94,156v5.26s4-3.35,3.94-3.11A24.25,24.25,0,0,1,134.94,156Zm7.4-6.54c1.53.08.89,2.15.81,2.79s1.77,1,1.77,1c.08-5.35-3.54-5.27-3.54-5.27C141,150.14,142.34,149.42,142.34,149.42Zm-2.09,0V147.9s-3.43.27-3.34,4.79c1.25.08,1.37-.72,1.67-1.76S138.87,149.37,140.25,149.42Zm-.8,3.75s-2.08-.24-2.13.87,2.37,2.47,4.62,1.92,2.65-3,2-2.79a2.85,2.85,0,0,1-1.53,0s-1,1.46-2.17,1.14S139.45,153.17,139.45,153.17Zm98.6-7.26a55.28,55.28,0,0,0,3.92,5.44c.37-3.47,2.11-4.31,2.36-5.14-5.19.36-6.28-.6-6.28-.6Zm15.89,2.69c.8.72.4-4.07,0-6-1.69,1.75-5.39,2.55-5.39,2.55C251.36,145.33,253.13,147.88,253.94,148.6Zm-4-7H234.07a15.14,15.14,0,0,0,15.85,0Zm0,16.11c-.08-.24,3.94,3.1,3.94,3.1v-5.26A23.72,23.72,0,0,1,249.92,157.69Zm-2.5-10.21s-3.62-.08-3.54,5.26c0,0,1.85-.32,1.77-1s-.72-2.71.81-2.79C246.46,149,247.82,149.71,247.42,147.48Zm2.8,3c.3,1,.42,1.83,1.67,1.76.09-4.53-3.34-4.79-3.34-4.79V149C249.93,149,249.92,149.48,250.22,150.51Zm-1.67,3.35c-1.21.32-2.17-1.14-2.17-1.14a2.89,2.89,0,0,1-1.53,0c-.64-.24-.24,2.24,2,2.79s4.66-.79,4.62-1.91-2.13-.88-2.13-.88S249.75,153.54,248.55,153.86Z"
                      transform="translate(0 -14.88)"
                    />
                    <g
                      id="Heritage_text"
                      data-name="Heritage text"
                      class="cls-2"
                    >
                      <text class="cls-4" transform="translate(147.08 184.75)">
                        HERITAGE
                      </text>
                    </g>
                  </g>
                </Link>
              </g>
            </svg>
          </div>
        </>
      )}
    </>
  );
};

export default HomeScreen;
