import React, { useEffect, useRef, useState } from "react";

const FadeIn = ({ children }) => {
  const sectionRef = useRef(null);
  const [inViewport, setInViewport] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const rect = sectionRef.current.getBoundingClientRect();
      const inView = rect.top < window.innerHeight && rect.bottom >= 0;
      setInViewport(inView);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className={` transform transition-opacity duration-1000  ${
        inViewport ? "opacity-100 scale-100" : " opacity-0 scale-90"
      }`}
     
    >
      {children}
    </div>
  );
};

export default FadeIn;
