import { Breadcrumbs } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CareerScreen from "./career-screen";
import { useDispatch } from "react-redux";
import { Contact } from "../Redux/Actions/FormActions";
import Select from "react-select";

const ContactScreen = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (key, value) => {
    console.log("key--", key, " Value--", value);
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = () => {
    // event.preventDefault();
    dispatch(Contact(formData)).then((res) => {
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    });
  };

  const options = [
    { value: "", label: "Select Services" },
    { value: "analytics_&_insights", label: "ANALYTICS & INSIGHTS" },
    { value: "brand_management", label: "BRAND MANAGEMENT" },
    { value: "client_development", label: "CLIENT DEVELOPMENT" },
    { value: "performance_management", label: "PERFORMANCE MANAGEMENT" },
    { value: "new_market_expansion", label: "NEW MARKET EXPANSION" },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: 0, // Set border to 0
      borderColor: state.isFocused ? "#00f" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #fff" : provided.boxShadow,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#A7A7A7", // Change the color of the selected value
    }),
  };

  return (
    <div>
      {/* breadcrumbs  */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="/"
        className="bg-[#F5F5F5] py-2 px-6 text-[#707070] @sm:hidden"
      >
        <Link underline="hover" color="#707070" to="/" className="fs-14">
          HOMEPAGE
        </Link>
        <Link
          underline="hover"
          color="#11134E"
          href="/contact"
          className="text-[#11134E] fs-14"
        >
          CONTACT
        </Link>
      </Breadcrumbs>

      <div className="pt-14 px-48 @sm:px-5 @sm:pt-8 @md:px-24 @2xl:px-60">
        {/* section 1 */}
        <section className=" mb-12 @sm:mb-5 ">
          {/* desktop heading  */}
          <div className="flex items-center justify-center mb-10 @sm:hidden">
            <div className="">
              <img
                className="@lg:w-[1.4vw]"
                src="images/about/spear.svg"
                alt="left border"
              />
            </div>
            <div className="border-1 border-[#DBDBDB] w-[36vw] ml-1"></div>
            <div className="fs-40 text-[#0b0b2f] mx-4 tracking-widest">
              CONTACT
            </div>
            <div className="border-1 border-[#DBDBDB] w-[36vw] mr-1"></div>
            <div className="">
              <img
                className="@lg:w-[1.4vw]  rotate-180"
                src="images/about/spear.svg"
                alt="left border"
              />
            </div>
          </div>
          {/* mobile heading  */}
          <div className="border-b-1 border-[#DBDBDB] @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden @sm:mb-4 ">
            <div className="fs-40 fw-400 text-center pb-1 tracking-widest">
              CONTACT
            </div>
          </div>
        </section>

        {/* mobile buttons  */}
        <section className="px-4 mb-6 @lg:hidden @lgtab:hidden @xl:hidden @2xl:hidden @md:hidden">
          <div
            style={{
              background: "radial-gradient(circle, #11115a, #0d0d3a)",
            }}
            className=" @sm:py-5 w-full h-6 rounded-md text-[#F2F2F2] fs-16 fw-400 flex flex-row justify-center items-center"
          >
            Linkedin
          </div>
          <div
            style={{
              background: "radial-gradient(circle, #11115a, #0d0d3a)",
            }}
            className=" mt-2 @sm:py-5 w-full h-6 rounded-md text-[#F2F2F2] fs-16 fw-400 flex flex-row justify-center items-center"
          >
            E-mail
          </div>
        </section>

        {/* section 2  */}
        <section className="mb-8">
          <div className="border-1 border-[#F5F5F5] bg-[#F5F5F5] px-32 py-6 @sm:px-8">
            {/* description  */}
            <div className="border-b-1 border-[#BCBCBC]">
              <p className="fs-32 fw-400 text-[#0B0B2F] text-center mb-4 @sm:text-left">
                Our client relations go as deep as our family roots. We serve
                both with integrity and passion. Let us know how we can help
                you.
              </p>
            </div>

            {/* inputs  */}
            <div className="mt-5">
              <div className="border-1 border-[#fff] bg-[#fff] py-3 px-5  @sm:pt-1 @sm:pb-1 rounded-md">
                <input
                  type="text"
                  placeholder="Name*"
                  className="fs-16 fw-400 text-[#A7A7A7] w-full"
                  style={{ outline: "none" }}
                  value={formData.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              </div>
              <div className="border-1 border-[#fff] bg-[#fff] py-3 rounded-md px-5 mt-3 @sm:pt-1 @sm:pb-1">
                <input
                  type="email"
                  placeholder="Company Email*"
                  className="fs-16 fw-400 text-[#A7A7A7] w-full"
                  style={{ outline: "none" }}
                  value={formData.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
              </div>
            </div>

            {/* desired service  */}
            <div className="border-b-1 border-[#BCBCBC] @sm:mt-6">
              <div className="text-center @sm:text-start text-[#0B0B2F] fs-32 fw-400 pt-6 pb-4">
                Desired Service
              </div>
            </div>

            {/* select input and message textarea  */}
            <div className="mt-6">
              <div className="border-1 border-[#fff] bg-[#fff] py-3 rounded-md px-5 @sm:px-2  @sm:pt-1 @sm:pb-1">
                <Select
                  className="fs-16"
                  id="selectOption"
                  options={options}
                  defaultValue={options[0]}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    border: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgray",
                      primary: "#11115A",
                    },
                  })}
                  styles={customStyles}
                  // value={.value}
                  onChange={(e) => {
                    handleInputChange("subject", e.value);
                  }}
                />
                {/* <select
                  className="fs-16 bg-[#fff] fw-400 text-[#A7A7A7] w-full"
                  style={{ outline: "none", boxShadow: "none" }}
                >
                  <option value="select">
                    <li className="py-5"> Select*</li>
                  </option>

                  <option value="ANALYTICS_&_INSIGHTS">
                    ANALYTICS & INSIGHTS
                  </option>
                  <option value="BRAND_MANAGEMENT">BRAND MANAGEMENT</option>
                  <option value="CLIENT_DEVELOPMENT">CLIENT DEVELOPMENT</option>
                  <option value="PERFORMANCE_MANAGEMENT">
                    PERFORMANCE MANAGEMENT
                  </option>
                  <option value="NEW_MARKET_EXPANSION">
                    NEW MARKET EXPANSION
                  </option>
                </select> */}
              </div>
              <div className=" mt-3  bg-[#fff] py-3 rounded-md px-[24px]  @sm:pt-1 @sm:pb-1">
                <textarea
                  placeholder="Message*"
                  rows="12"
                  cols="50"
                  className="fs-16 fw-400 text-[#A7A7A7] w-full"
                  style={{ outline: "none" }}
                  value={formData.message}
                  onChange={(e) => handleInputChange("message", e.target.value)}
                ></textarea>
              </div>
            </div>

            {/* submit button  */}
            <div className="@sm:hidden">
              {/* <div
                style={{
                  background: "radial-gradient(circle, #11115a, #0d0d3a)",
                }}
                className="w-full h-10 @sm:py-5 rounded-[7px] mt-4 text-[#F2F2F2] fs-14 fw-400 flex flex-row justify-center items-center cursor-pointer"
              >
                Submit
              </div> */}

              <button
                style={{
                  background: "radial-gradient(circle, #11115a, #0d0d3a)",
                }}
                className="text-white fs-24 fw-400  p-1 w-[100%] rounded-md py-2 @sm:hidden mt-4"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit
              </button>
            </div>
          </div>

          {/* mobile submit button  */}
          <div className="mt-5 @lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden">
            {/* <div
              style={{
                background: "radial-gradient(circle, #11115a, #0d0d3a)",
              }}
              className=" @sm:py-5 rounded-md w-full h-6  text-[#F2F2F2] fs-14 fw-400 flex flex-row justify-center items-center"
            >
              Submit
            </div> */}

            <button
              style={{
                background: "radial-gradient(circle, #11115a, #0d0d3a)",
              }}
              className="text-white fs-16 fw-400  p-1 w-[100%] rounded-md py-2  mt-4"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </section>

        {/* section 3 */}
        <section className="mb-12 @sm:hidden">
          <div className="flex flex-row justify-end items-center">
            <div className="fs-40 fw-400 text-[#0B0B2F] tracking-widest">
              GET IN TOUCH -
            </div>
            <div className=" ml-7">
              <img
                src="images/contact/email.png"
                className="h-8 w-8 cursor-pointer"
                alt=""
              />
            </div>
            <div className=" ml-4">
              <img
                src="images/contact/linkedin.png"
                className="h-8 w-8 cursor-pointer"
                alt=""
              />
            </div>
          </div>
        </section>

        {/* career mobile screen  */}
        {/* <section className="@lg:hidden @md:hidden @lgtab:hidden @xl:hidden @2xl:hidden">
          <div className="">
            <CareerScreen />
          </div>
        </section> */}
      </div>
    </div>
  );
};

export default ContactScreen;
